<template>
    <div>
        <Header :num="'5'"></Header>
        <Banner :src="bannerImg" :title="title"></Banner>
        <div class="contact">
            <section-title
                title="contact us"
                sub-title="联系我们"
            ></section-title>
            <ul>
                <li><img style="vertical-align: middle;margin-right: 8px;" src="../assets/icon-time.png" alt=""><span  style="vertical-align: middle" >服务时间: 周一至周五 (9:00 - 18:00)</span></li>
                <li><img  style="vertical-align: middle;margin-right: 8px;"  src="../assets/icon-contact.png" alt=""><span   style="vertical-align: middle" >联系电话: 0731-82297876</span></li>
                <li><img  style="vertical-align: middle;margin-right: 8px;"  src="../assets/icon-email.png" alt=""><span style="vertical-align: middle">联系邮箱: liyubai@gneway.com</span></li>
                <li><img   style="vertical-align: middle;margin-right: 8px;"  src="../assets/icon-address.png" alt=""><span  style="vertical-align: middle">地址: 湖南省长沙市岳麓区西中心T2-1610室</span></li>
            </ul>



        </div>
        <!--您的需求-->
        <div class="sendContent">
            <div class="childContent">
                <Form ref="formInline" :model="formInline" :rules="ruleInline">
                    <span class="title">您的需求</span>
                    <Row :gutter="20">
                        <Col>
                            <FormItem prop="user">
                                <Input type="text" v-model="formInline.user" placeholder="您的姓名">
                                    <Icon size="20" type="ios-person-outline" slot="prepend"></Icon>
                                </Input>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row :gutter="20">
                        <Col>
                            <FormItem prop="email">
                                <Input type="text" v-model="formInline.email" placeholder="您的邮箱">
                                    <Icon size="20"  type="ios-mail-outline" slot="prepend"></Icon>
                                </Input>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row  :gutter="20">
                        <Col>
                            <FormItem prop="phone">
                                <Input type="text" v-model="formInline.phone" placeholder="您的手机号码">
                                    <Icon size="20"  type="ios-call-outline" slot="prepend"></Icon>
                                </Input>
                            </FormItem>
                        </Col>
                    </Row>

                    <Row  :gutter="20">
                        <Col>
                            <FormItem prop="words">
                                <Input type="textarea" :autosize="true" v-model="formInline.words" placeholder="留言">
                                </Input>
                            </FormItem>
                        </Col>
                    </Row>
                    <Row  :gutter="20">
                        <Col>
                            <FormItem>
                                <Button type="primary" @click="handleSubmit('formInline')">发送</Button>
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
            </div>

        </div>


        <!--地图-->
        <baidu-map :center="center" :zoom="zoom" @ready="handler" style="height:600px" @click="getClickInfo" :scroll-wheel-zoom='false'>
<!--        <baidu-map  style="height:600px"  :scroll-wheel-zoom='true'>-->
        </baidu-map>

<!--        <div style="margin-left: 94%">-->
<!--            <Affix :offset-bottom="80" style="">-->
<!--                <ButtonGroup vertical size="large">-->
<!--                    <Button type="primary" icon="logo-facebook"></Button>-->
<!--                    <Button  type="primary" icon="logo-twitter"></Button>-->
<!--                    <Button  type="primary"  icon="logo-googleplus"></Button>-->
<!--                    <Button  type="primary" icon="logo-tumblr"></Button>-->
<!--                </ButtonGroup>-->
<!--            </Affix>-->
<!--        </div>-->

        <Footer></Footer>
        <BackTop :height="100" :bottom="200">
            <div class="top">返回顶部</div>
        </BackTop>

    </div>
</template>
<script>
import Header from '../components/Header'
import Footer from '../components/Footer'
import Banner from '../components/Banner'
import SectionTitle from '../components/SectionTitle'

export default {
    name: 'Contact',
    components: {
        Header,
        Footer,
        Banner,
        SectionTitle
    },
    data() {
        return {
            bannerImg: require('../assets/banner-contact.jpg'),
            mapImg: require('../assets/map-02.png'),
            title: '联系我们',

            center: {lng: 112.920118, lat: 28.215061},
            zoom: 13,
            formInline: {
                user: '',
                password: ''
            },
            ruleInline: {
                user: [
                    { required: true, message: 'Please fill in the user name', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: 'Please fill in the password.', trigger: 'blur' },
                    { type: 'string', min: 6, message: 'The password length cannot be less than 6 bits', trigger: 'blur' }
                ]
            }

        }
    },
    // mounted(){
    //     this.getCity()
    // },
    methods:{
        handleSubmit(){

        },


        handler ({BMap, map}) {
            var point = new BMap.Point(112.920118, 28.215061)
            map.centerAndZoom(point, 13)
            var marker = new BMap.Marker(point) // 创建标注
            map.addOverlay(marker) // 将标注添加到地图中

            var label = new BMap.Label('湖南省长沙市岳麓区望城坡街道西中心T2栋', {offset: new BMap.Size(-98, 36) });

            // label.setStyle({ borderColor: '#999',backgroundColor: 'Red',color: '#fff',height: '30px',lineHeight:'30px',width: '240px' })
            label.setStyle({ borderColor: '#f9f9f9' ,backgroundColor:'#ff8355',height:'24px',lineHeight:'24px',width:'240px',color: '#fff',textAlign: "center",})
            marker.setLabel(label);
            var circle = new BMap.Circle(point, 6, { strokeColor: 'Red', strokeWeight: 6, strokeOpacity: 1, Color: 'Red', fillColor: '#f03' })
            map.addOverlay(circle)

            //标注下添加文字
            // var label = new BMap.Label("湖南省长沙市岳麓区西中心T2栋", { point: new BMap.Point(112.920118, 28.215061), offset: new BMap.Size(3, -6) });
            // var label = new BMap.Label('湖南省长沙市岳麓区望城坡街道西中心T2栋', { point: new BMap.Point(point), offset: new BMap.Size(3, -6) });



        },
        getClickInfo (e) {
            console.log(e.point.lng)
            console.log(e.point.lat)
            this.center.lng = e.point.lng
            this.center.lat = e.point.lat
        }
    }

}
</script>
<style lang="less" scoped>
.contact {
    padding: 30px 0;
    max-width: 1200px;
    margin: 0 auto;
    ul {
        width: 860px;
        margin: 30px auto 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        li {
            width: 48%;
            background: #eee;
            margin-bottom: 30px;
            padding: 30px;
            font-size: 16px;
            box-sizing: border-box;
        }
    }
}
    .sendContent {
        background: url('../assets/send-contacts-copy.jpg') no-repeat;
        background-size: 100% 100%;
        width: 100%;
        margin: 0 auto;
        .childContent {
            width: 700px;
            margin: 0 auto;
            padding: 20px 0;
            .title {
                display: block;
                margin: 20px 0;
                font-size: 24px;
                font-weight: bold;
                color: #fff;
            }
        }

    }
/deep/ .ivu-input-group .ivu-input, .ivu-input-group .ivu-input-inner-container{
    height: 50px;
    font-size: 14px;
}
/*/deep/ .ivu-btn-primary{*/
/*   background-color: #737373;*/
/*    border-color: #737373;*/
/*}*/
/deep/ .ivu-input-group-append, .ivu-input-group-prepend{
    padding: 4px 20px;
}
.top{
    padding: 10px;
    background: rgba(0, 153, 229, .7);
    color: #fff;
    text-align: center;
    border-radius: 2px;
}
</style>
