<template>
    <div>
        <Header :num="'3'"></Header>
        <Banner :src="bannerImg" :title="title"></Banner>
        <section-title title="our service" sub-title="服务内容"></section-title>
        <div class="service">
            <p>
                湖南新展信息技术有限公司能为客户提供专业的app项目策划、设计、开发、管理、运营经验，
                为客户提供规范的手机软件定制开发项目管理、数据整合和分析服务。
            </p>

            <div class="service_box">
                <div class="service_boxl">
                    <h3 style="line-height: 40px; color: #49ccff; font-size: 20px; font-weight: bold;margin-top: 120px;">Android开发</h3>
                    <p>新展致力于为各个行业客户提供Android APP开发服务，让企业迅速抓住占据50%以上的国内安卓移动应用市场。
                        HTML5网站开发
                        html5手机网站开发公司多年致力于手机网站建设、html5移动手机软件定制，为客户提供一站式的手机网站定制开发服务。
                    </p>
                    <h3  style="line-height: 40px; color: #49ccff; font-size: 20px; font-weight: bold;margin-top: 80px;">HTML5网站开发</h3>
                    <p>html5手机网站开发公司多年致力于手机网站建设、html5移动手机软件定制，为客户提供一站式的手机网站定制开发服务。
                    </p>
                </div>
                <div class="service_boxm">
                    <img src="../assets/app-pic01.png" alt="" style="margin-left: 12px;width: 317px;height: 537px;">
                </div>
                <div class="service_boxr">
                    <h3  style="line-height: 40px; color: #49ccff; font-size: 20px; font-weight: bold;margin-top: 40px;">iOS开发</h3>
                    <p>新展凭借领先的手机应用软件开发技术和丰富经验，提供ios APP开发平台的策划、开发、运营等一站式的行业解决方案，解决企业转型难题。
                    </p>
                    <h3  style="line-height: 40px; color: #49ccff; font-size: 20px; font-weight: bold;margin-top: 160px;">微信开发</h3>
                    <p>新展为客户提供专业的微信公众平台二次开发服务，为企业打造最强营销力的平台。
                    </p>
                </div>
            </div>
            <section-title title="our service" sub-title="手机应用开发优势"></section-title>
            <div class="advantage">
                <ul>
                    <li style="background-color: #7fd528;width: 24%">
                        <h3>500+</h3>
                        <span >面向全球，提供顶级服务</span>
                        <p>专注安卓、ios、微信、html5等移动APP平台开发服务。</p>
                    </li>
                    <li style="background-color: #feae3d;width: 24%">
                        <h3>1300+</h3>
                        <span>项目产品高度来开发</span>
                        <p>“专注、极致、口碑、快”-手机应用软件开发的理念。</p>
                    </li>
                    <li style="background-color: #51b7ff;width: 24%">
                        <h3>5000+</h3>
                        <span>规范化管理</span>
                        <p>项目各阶段都由标准化的过程控制，形成了一整套完整的项目文档，最大程度的保证项目顺利上线。</p>
                    </li>
                    <li style="background-color: #fd5a79;width: 24%">
                        <h3>8000+</h3>
                        <span>精准的数据分析能力</span>
                        <p>借助我们的大量数据和案例，帮助客户打造一款更符合受众人群的产品。</p>
                    </li>
                </ul>
            </div>
        </div>
        <Footer></Footer>
        <BackTop :height="100" :bottom="200">
            <div class="top">返回顶部</div>
        </BackTop>
    </div>
</template>
<script>
import Header from '../components/Header'
import Footer from '../components/Footer'
import Banner from '../components/Banner'
import SectionTitle from '../components/SectionTitle'

export default {
    name: 'Service',
    components: {
        Header,
        Footer,
        Banner,
        SectionTitle
    },
    data() {
        return {
            bannerImg: require('../assets/app-banner.jpg'),
            bannerImg02: require('../assets/tese04.png'),
            title: '移动端定制开发',
            services: [
                {
                    icon: require('../assets/icon-service.png'),
                    title: '项目实施',
                    id: Math.floor(Math.random() * 10000),
                    comments:
                        '给企业提供项目实施经验，为各职能部门提供现场培训和远程支持。帮助解决企业信息化过程中遇到的困难，强力推进项目的进行'
                },
                {
                    icon: require('../assets/customer-icon-01.png'),
                    title: '客服服务',
                    id: Math.floor(Math.random() * 10000),
                    comments:
                        '工作时间内提供 QQ、电话、Email等多种方式，实时解决客户使用中遇到的问题。并收集提出的需求之后对软件加以改进。这样为企业提供私人医生式的专业服务'
                },
                {
                    icon: require('../assets/customer-icon-02.png'),
                    title: '软件维护',
                    id: Math.floor(Math.random() * 10000),
                    comments: '为系统的平稳运行提供全方位的维护管理'
                },
                {
                    icon: require('../assets/customer-icon-03.png'),
                    title: '免费升级',
                    id: Math.floor(Math.random() * 10000),
                    comments:
                        '在维护期间免费升级软件。升级内容一般包括：根据中国民用航空局规定变动情况，增加新的软件功能；根据其他客户使用情况，增加通用功能；修复软件缺陷'
                },
                {
                    icon: require('../assets/customer-icon-04.png'),
                    title: '定制开发',
                    id: Math.floor(Math.random() * 10000),
                    comments:
                        '我们有着熟悉通航公司管理，了解民航法规，技术扎实并不断创新的IT研发团队。可结合企业需要定制开发功能，给企业带来各种可能的解决方案'
                },
                {
                    icon: require('../assets/icon-service.png'),
                    title: '项目实施',
                    id: Math.floor(Math.random() * 10000),
                    comments:
                        '给企业提供项目实施经验，为各职能部门提供现场培训和远程支持。帮助解决企业信息化过程中遇到的困难，强力推进项目的进行'
                }
            ]
        }
    },
    methods: {
        toContact(){
            this.$router.push('/contact')
        }
    }
}
</script>
<style lang="less" scoped>
.service {
    max-width: 1200px;
    margin: 0 auto;
    padding-bottom: 30px;
    & > p {
        line-height: 30px;
    }
    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 30px;
        li {
            width: 31%;
            background: #f7f8fa;
            margin-bottom: 30px;
            text-align: center;
            padding: 30px;
            box-sizing: border-box;
            transition: all 0.5s;
            h2 {
                font-size: 18px;
                margin: 10px;
            }
            p {
                line-height: 30px;
            }
            &:hover {
                background: #1a88c7;
                color: #fff;
            }
        }
    }
}

.passAirport02 {
    margin-top: 2px;
    width: 100%;
    height: 300px;
    background: url('../assets/banner-home-make.jpg') no-repeat;
    background-size: 100% 100%;
}
.passAirport2 {
    margin-top: 4px;
    width: 100%;
    height: 300px;
    background: url('../assets/service-02.jpg') no-repeat;
    background-size: 100% 100%;
}
.passAirport4 {
    margin-top: 4px;
    width: 100%;
    height: 300px;
    background: url('../assets/service-04.jpg') no-repeat;
    background-size: 100% 100%;
}
.passAirport3 {
    margin-top: 4px;
    width: 100%;
    height: 300px;
    background: url('../assets/service-03.jpg') no-repeat;
    background-size: 100% 100%;
}
.contentCneter {
    margin: 0 auto;
    max-width: 1200px;
    h2 {
        font-size: 24px;
        font-weight: bold;
        padding-top: 80px;
        color: #fff;
    }
    .titleContent{
        padding: 14px 0;
        p {
            font-size: 14px;
            color: #fff;
        }
    }
    .more {
        margin-top: 14px;
        span{
            color: #fff;
            /*font-size: 16px;*/
            &:hover {
                cursor: pointer;
                color: #409eff;
                /*font-size: 16px;*/
            }

        }
        img {
            &:hover {
                cursor: pointer;
                transform: translateX(-10px);
            }
        }

    }
}

.about {
    max-width: 1200px;
    margin: 0 auto;
    padding: 30px 0 60px;
    .compony {
        margin-top: 30px;
        display: flex;
        .img {
            flex: 1;
            img {
                max-width: 100%;
            }
        }
        .info {
            flex: 2;
            margin-left: 60px;
            h2 {
                margin-bottom: 20px;
            }
            h3{
                margin-bottom: 10px;
                color: #6d6d6d;
                border-bottom: 1px solid #000000;

            }
        }
    }
    .team {
        background: url(../assets/project-about-01.jpg) no-repeat right top;
        margin-top: 100px;
        overflow: hidden;
        .text {
            width: 60%;
            background: rgba(238, 238, 238, 0.6);
            padding: 30px;
            margin: 60px;
        }
    }
    p {
        line-height: 30px;
        margin-bottom: 10px;
    }
}


.passAirport {
    margin-top: 30px;
    width: 100%;
    height: 300px;
    background: url('../assets/service-01.jpg') no-repeat;
    background-size: 100% 100%;
}
.contentCneter {
    margin: 0 auto;
    max-width: 1200px;
    h2 {
        font-size: 24px;
        font-weight: bold;
        padding-top: 80px;
        color: #fff;
    }
    .titleContent{
        padding: 14px 0;
        p {
            font-size: 14px;
            color: #fff;
        }
    }
    .more {
        margin-top: 14px;
        span{
            color: #fff;
            /*font-size: 16px;*/
            &:hover {
                cursor: pointer;
                color: #409eff;
                /*font-size: 16px;*/
            }

        }
        img {
            &:hover {
                cursor: pointer;
                transform: translateX(-10px);
            }
        }

    }
}
.service_box {
    height: 600px;
    margin-top: 40px;
    .service_boxl{
        float: left;
        width: 30%;
        margin-top: 60px;
        margin-left: 60px;
    }
    .service_boxm {
        float:left;
        width: 30%;
    }
    .service_boxr {
        float: left;
        width: 30%;
    }
}
    ul {
        li {
            h3 {
                font-weight: bold;font-size: 30px;
            }
            span {
                display:block;
                padding: 20px 0;
                color: #3e3c3d;
            }
        }
    }

.top{
    padding: 10px;
    background: rgba(0, 153, 229, .7);
    color: #fff;
    text-align: center;
    border-radius: 2px;
}
</style>
