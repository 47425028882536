import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import $ from "jquery"
import iView from 'iview'

import 'iview/dist/styles/iview.css'
import BaiduMap from 'vue-baidu-map'

Vue.use(BaiduMap, {
    ak: 'm654E5wPFHXp812VWGsGphE3lBMBSpu1'
})

Vue.use(iView)

Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
