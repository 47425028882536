import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Contact from '../views/Contact'
import Projects from '../views/Projects'
import Service from '../views/Service'
import News from '../views/News'
import NewsDetail from '../views/NewsDetail'
import Foc from '../views/Foc'
import Repair from '../views/Repair'
import Trajectory from '../views/Trajectory'
import ServiceApp from '../views/ServiceApp'
import ServiceParams from '../views/ServiceParams'
import ServiceSoft from '../views/ServiceSoft'
import ServiceWechatOpration from '../views/ServiceWechatOpration'


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {}
    },
    {
        path: '/projects',
        component: Projects,
        name:'Projects',
        // children: [
        //     {
        //         path: '/',
        //         component: Foc,
        //         name: 'ProjectsIndex'
        //     },
        //     {
        //         path: 'foc',
        //         component: Foc,
        //         name: 'Foc'
        //     },
        //     {
        //         path: 'repair',
        //         component: Repair,
        //         name: 'Repair'
        //     },
        //     {
        //         path: 'trajectory',
        //         component: Trajectory,
        //         name: 'Trajectory'
        //     }
        // ]
    },
    {
        path: '/foc',
        component: Foc,
        name: 'Foc'
    },
    {
        path: '/repair',
        component: Repair,
        name: 'Repair'
    },
    {
        path: '/trajectory',
        component: Trajectory,
        name: 'Trajectory'
    },
    {
        path: '/service',
        component: Service,
        name: 'Service'
    },
    {
        path: '/serviceApp',
        component: ServiceApp,
        name: 'ServiceApp'
    },
    {
        path: '/serviceParams',
        component: ServiceParams,
        name: 'ServiceParams'
    },
    {
        path: '/serviceSoft',
        component: ServiceSoft,
        name: 'ServiceSoft'
    },
    {
        path: '/serviceWechatOpration',
        component: ServiceWechatOpration,
        name: 'ServiceWechatOpration'
    },
    {
        path: '/news',
        component: News,
        name: 'News'
    },
    {
        path: '/news/:id',
        component: NewsDetail,
        name: 'NewsDetail'
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact,
        meta: {}
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/About.vue')
    }
]

const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from,savedPosition) {
        return {
            x: 0,
            y: 0
        }
    }

})

export default router
