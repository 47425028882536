<template>
    <div>
        <Header></Header>
        <Banner :src="bannerImg"></Banner>
        <div class="news-detail">
            <h2>新闻标题施蒂利克发送方了胜多负少发</h2>
            <h3>2020-02-39 12:12</h3>
            <p>
                sdfksdf水电费水电费水电费水电费是的开发商的发，水电费卡死了的防守打法水电费，试登录反馈水电费水电费水电费，沙龙的反馈水电费水电费sdfksdf水电费水电费水电费水电费是的开发商的发，水电费卡死了的防守打法水电费，试登录反馈水电费水电费水电费，沙龙的反馈水电费水电费sdfksdf水电费水电费水电费水电费是的开发商的发，水电费卡死了的防守打法水电费，试登录反馈水电费水电费水电费，沙龙的反馈水电费水电费sdfksdf水电费水电费水电费水电费是的开发商的发，水电费卡死了的防守打法水电费，试登录反馈水电费水电费水电费，沙龙的反馈水电费水电费
            </p>
            <p>
                sdfksdf水电费水电费水电费水电费是的开发商的发，水电费卡死了的防守打法水电费，试登录反馈水电费水电费水电费，沙龙的反馈水电费水电费
            </p>
            <p>
                <img src="../assets/news.jpg" alt="" />
            </p>
            <p>
                sdfksdf水电费水电费水电费水电费是的开发商的发，水电费卡死了的防守打法水电费，试登录反馈水电费水电费水电费，沙龙的反馈水电费水电费
            </p>
            <p>
                sdfksdf水电费水电费水电费水电费是的开发商的发，水电费卡死了的防守打法水电费，试登录反馈水电费水电费水电费，沙龙的反馈水电费水电费
            </p>
            <p>
                sdfksdf水电费水电费水电费水电费是的开发商的发，水电费卡死了的防守打法水电费，试登录反馈水电费水电费水电费，沙龙的反馈水电费水电费
            </p>
            <p>
                sdfksdf水电费水电费水电费水电费是的开发商的发，水电费卡死了的防守打法水电费，试登录反馈水电费水电费水电费，沙龙的反馈水电费水电费
            </p>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import Header from '../components/Header'
import Footer from '../components/Footer'
import Banner from '../components/Banner'

export default {
    name: 'NewsDetail',
    components: {
        Header,
        Footer,
        Banner
    },
    data() {
        return {
            bannerImg: require('../assets/banner-projects.jpg')
        }
    }
}
</script>
<style lang="less" scoped>
.news-detail {
    max-width: 1200px;
    margin: 60px auto;
    h2 {
        text-align: center;
        margin-bottom: 20px;
    }
    h3 {
        text-align: center;
        margin-bottom: 20px;
        color: #999;
    }
    p {
        line-height: 30px;
        margin-bottom: 10px;
        img {
            max-width: 100%;
        }
    }
}
</style>
