<template>
    <div>
        <Header :num="'3'"></Header>
        <Banner :src="bannerImg" :title="title"></Banner>
        <section-title title="our service" sub-title="软件特色"></section-title>
<!--        <div class="service">-->
<!--            <p>-->
<!--                为了专注核心竞争力业务和降低软件项目成本，为了解决雇佣技术人员或者无法管理技术人员的难题-->
<!--            </p>-->
<!--            <p>-->
<!--                新展信息致力于称为客户优先选择的高新技术产品与服务提供商。以服务至上为遵旨，以技术创新为核心，累计口碑，打造品牌，与合作伙伴共存共赢。-->
<!--            </p>-->
<!--            <ul>-->
<!--                <li v-for="item of services" :key="item.id">-->
<!--                    <p>-->
<!--                        <img :src="item.icon" />-->
<!--                    </p>-->
<!--                    <h2>{{ item.title }}</h2>-->
<!--                    <h3>{{ item.subTitle }}</h3>-->
<!--                    <p>{{ item.comments }}</p>-->
<!--                </li>-->
<!--            </ul>-->
<!--        </div>-->



        <div style="max-width:1200px;margin: 0 auto;">
            <List item-layout="vertical">
                <ListItem v-for="item in data" :key="item.title">
                    <ListItemMeta  :title="item.title" :description="item.description" />
                    {{ item.content }}
                    <template slot="extra">
                        <img :src="item.src" style="width: 280px">
                    </template>
                </ListItem>
            </List>
        </div>


        <section-title title="our service" sub-title="涉及系统"></section-title>
        <div class="imgContent">
            <Banner :src="bannerImg02" style="margin-top: -80px"></Banner>
        </div>

        <!--放到软件定制开发页面-->
<!--        <div class="about">-->
<!--            <div class="compony">-->
<!--&lt;!&ndash;                <div class="img">&ndash;&gt;-->
<!--&lt;!&ndash;                    <img src="../assets/project-about-02.jpg" alt="" />&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--                <div class="info">-->
<!--                    <h2>新展软件特色</h2>-->
<!--                    <h3>我们的理念</h3>-->
<!--                    <p>-->
<!--                        从专业的角度，挖掘客户需求，遵循国际软件工程开发规范，辅助客户作出良好选择，并为软件产品正常运行提供维护、升级、功能扩展等做好铺垫， 让您的软件成为您成功道路上的得力助手！新展相信：定制的才是适合的！-->
<!--                    </p>-->
<!--                    <h3>我们的愿望</h3>-->
<!--                    <p>-->
<!--                        无论大小、无论行业，新展为客户企业提供合适的定制软件。新展郑重承诺：无论软件产品大小，一律按需定制。-->
<!--                    </p>-->
<!--                    <h3>我们的坚持</h3>-->
<!--                    <p>-->
<!--                        新展坚持以服务致上为宗旨，以技术创新为核心-->
<!--                    </p>-->
<!--                    <h3>我们的优势</h3>-->
<!--                    <p>-->
<!--                        成熟的开发框架、专业稳定的技术团队，按需开发，按需收费-->
<!--                    </p>-->
<!--                </div>-->
<!--                <Banner :src="bannerImg02"></Banner>-->
<!--            </div>-->
<!--        </div>-->



        <Footer></Footer>
        <BackTop :height="100" :bottom="200">
            <div class="top">返回顶部</div>
        </BackTop>
    </div>
</template>
<script>
import Header from '../components/Header'
import Footer from '../components/Footer'
import Banner from '../components/Banner'
import SectionTitle from '../components/SectionTitle'

export default {
    name: 'Service',
    components: {
        Header,
        Footer,
        Banner,
        SectionTitle
    },
    data() {
        return {
            bannerImg: require('../assets/soft-banner.jpg'),
            bannerImg02: require('../assets/tese04.png'),
            title: '软件定制开发',
            data: [
                {
                    title: '我们的理念',
                    description: 'Our philosophy',
                    avatar: 'https://dev-file.iviewui.com/userinfoPDvn9gKWYihR24SpgC319vXY8qniCqj4/avatar',
                    src:require('../assets/soft-01.jpg'),
                    content: '从专业的角度，挖掘客户需求，遵循国际软件工程开发规范，辅助客户作出良好选择，并为软件产品正常运行提供维护、升级、功能扩展等做好铺垫， 让您的软件成为您成功道路上的得力助手！新展相信：定制的才是适合的！'
                },
                {
                    title: '我们的愿望',
                    description: 'Our wish',
                    src:require('../assets/soft-02.jpg'),
                    avatar: 'https://dev-file.iviewui.com/userinfoPDvn9gKWYihR24SpgC319vXY8qniCqj4/avatar',
                    content: '无论大小、无论行业，新展为客户企业提供合适的定制软件。新展郑重承诺：无论软件产品大小，一律按需定制。'
                },
                {
                    title: '我们的坚持',
                    description: 'Our insistence',
                    src:require('../assets/soft-03.jpg'),
                    avatar: 'https://dev-file.iviewui.com/userinfoPDvn9gKWYihR24SpgC319vXY8qniCqj4/avatar',
                    content: '新展坚持以服务致上为宗旨，以技术创新为核心'
                },
                {
                    title: '我们的优势',
                    description: 'Our strengths',
                    src:require('../assets/soft-04.jpg'),
                    avatar: 'https://dev-file.iviewui.com/userinfoPDvn9gKWYihR24SpgC319vXY8qniCqj4/avatar',
                    content: '成熟的开发框架、专业稳定的技术团队，按需开发，按需收费'
                }

            ],
            services: [
                {
                    icon: require('../assets/icon-service.png'),
                    title: '项目实施',
                    id: Math.floor(Math.random() * 10000),
                    comments:
                        '给企业提供项目实施经验，为各职能部门提供现场培训和远程支持。帮助解决企业信息化过程中遇到的困难，强力推进项目的进行'
                },
                {
                    icon: require('../assets/customer-icon-01.png'),
                    title: '客服服务',
                    id: Math.floor(Math.random() * 10000),
                    comments:
                        '工作时间内提供 QQ、电话、Email等多种方式，实时解决客户使用中遇到的问题。并收集提出的需求之后对软件加以改进。这样为企业提供私人医生式的专业服务'
                },
                {
                    icon: require('../assets/customer-icon-02.png'),
                    title: '软件维护',
                    id: Math.floor(Math.random() * 10000),
                    comments: '为系统的平稳运行提供全方位的维护管理'
                },
                {
                    icon: require('../assets/customer-icon-03.png'),
                    title: '免费升级',
                    id: Math.floor(Math.random() * 10000),
                    comments:
                        '在维护期间免费升级软件。升级内容一般包括：根据中国民用航空局规定变动情况，增加新的软件功能；根据其他客户使用情况，增加通用功能；修复软件缺陷'
                },
                {
                    icon: require('../assets/customer-icon-04.png'),
                    title: '定制开发',
                    id: Math.floor(Math.random() * 10000),
                    comments:
                        '我们有着熟悉通航公司管理，了解民航法规，技术扎实并不断创新的IT研发团队。可结合企业需要定制开发功能，给企业带来各种可能的解决方案'
                },
                {
                    icon: require('../assets/icon-service.png'),
                    title: '项目实施',
                    id: Math.floor(Math.random() * 10000),
                    comments:
                        '给企业提供项目实施经验，为各职能部门提供现场培训和远程支持。帮助解决企业信息化过程中遇到的困难，强力推进项目的进行'
                }
            ]
        }
    },
    methods: {
        toContact(){
            this.$router.push('/contact')
        }
    }
}
</script>
<style lang="less" scoped>
.service {
    max-width: 1200px;
    margin: 0 auto;
    padding-bottom: 30px;
    & > p {
        line-height: 30px;
    }
    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 30px;
        li {
            width: 31%;
            background: #f7f8fa;
            margin-bottom: 30px;
            text-align: center;
            padding: 30px;
            box-sizing: border-box;
            transition: all 0.5s;
            h2 {
                font-size: 18px;
                margin: 10px;
            }
            p {
                line-height: 30px;
            }
            &:hover {
                background: #1a88c7;
                color: #fff;
            }
        }
    }
}

.passAirport02 {
    margin-top: 2px;
    width: 100%;
    height: 300px;
    background: url('../assets/banner-home-make.jpg') no-repeat;
    background-size: 100% 100%;
}
.passAirport2 {
    margin-top: 4px;
    width: 100%;
    height: 300px;
    background: url('../assets/service-02.jpg') no-repeat;
    background-size: 100% 100%;
}
.passAirport4 {
    margin-top: 4px;
    width: 100%;
    height: 300px;
    background: url('../assets/service-04.jpg') no-repeat;
    background-size: 100% 100%;
}
.passAirport3 {
    margin-top: 4px;
    width: 100%;
    height: 300px;
    background: url('../assets/service-03.jpg') no-repeat;
    background-size: 100% 100%;
}
.contentCneter {
    margin: 0 auto;
    max-width: 1200px;
    h2 {
        font-size: 24px;
        font-weight: bold;
        padding-top: 80px;
        color: #fff;
    }
    .titleContent{
        padding: 14px 0;
        p {
            font-size: 14px;
            color: #fff;
        }
    }
    .more {
        margin-top: 14px;
        span{
            color: #fff;
            /*font-size: 16px;*/
            &:hover {
                cursor: pointer;
                color: #409eff;
                /*font-size: 16px;*/
            }

        }
        img {
            &:hover {
                cursor: pointer;
                transform: translateX(-10px);
            }
        }

    }
}

.about {
    max-width: 1200px;
    margin: 0 auto;
    padding: 30px 0 60px;
    .compony {
        margin-top: 30px;
        display: flex;
        .img {
            flex: 1;
            img {
                max-width: 100%;
            }
        }
        .info {
            flex: 2;
            margin-left: 60px;
            h2 {
                margin-bottom: 20px;
            }
            h3{
                margin-bottom: 10px;
                color: #6d6d6d;
                border-bottom: 1px solid #000000;

            }
        }
    }
    .team {
        background: url(../assets/project-about-01.jpg) no-repeat right top;
        margin-top: 100px;
        overflow: hidden;
        .text {
            width: 60%;
            background: rgba(238, 238, 238, 0.6);
            padding: 30px;
            margin: 60px;
        }
    }
    p {
        line-height: 30px;
        margin-bottom: 10px;
    }
}


.passAirport {
    margin-top: 30px;
    width: 100%;
    height: 300px;
    background: url('../assets/service-01.jpg') no-repeat;
    background-size: 100% 100%;
}
.contentCneter {
    margin: 0 auto;
    max-width: 1200px;
    h2 {
        font-size: 24px;
        font-weight: bold;
        padding-top: 80px;
        color: #fff;
    }
    .titleContent{
        padding: 14px 0;
        p {
            font-size: 14px;
            color: #fff;
        }
    }
    .more {
        margin-top: 14px;
        span{
            color: #fff;
            /*font-size: 16px;*/
            &:hover {
                cursor: pointer;
                color: #409eff;
                /*font-size: 16px;*/
            }

        }
        img {
            &:hover {
                cursor: pointer;
                transform: translateX(-10px);
            }
        }

    }
}

    .imgContent {
        max-width:1200px;
        margin: 0 auto;
    }
.top{
    padding: 10px;
    background: rgba(0, 153, 229, .7);
    color: #fff;
    text-align: center;
    border-radius: 2px;
}


</style>
