<template>
  <div>
    <Header   :num="'2'"></Header>
    <Banner :src="bannerImg" :title="title"></Banner>




    <div class="about">
      <div class="compony">
        <div class="img">
          <img src="../assets/repaire-01.jpg" alt="" />
        </div>
        <div class="info">
          <h2>公务机维修平台</h2>
          <p>
            维修管理平台根据公务机运营商机务维修部门工作操作流程,将业务划分为
            工程管理子系统、生产计划与控制子系统、航材工具管理子系统、
            质量管理子系统、SMS安全管理子系统、培训管理子系统、技术合同管理子系统、系统基础管理子系统;本平台覆盖运行商机务部门全部业务,且能打通上下游业务流程,形成流程闭环,无需任何线下操作,充分考虑降低维修成本,提高工作效率！
          </p>
          <p>系统人性化设计灵活配置将最大自由度交给操作人员,系统根据运营商内部结构定制管理流程,自主配置各监控周期;
            支持移动办公,多形式推送消息（邮件、手机）。</p>

        </div>
      </div>

    </div>
    <section-title
        title="our strengths"
        sub-title="我们的优势"
    ></section-title>

    <div class="itemTitle">
      <ul>
        <li>
          <img src="../assets/maint-01-copy.png" alt="">
          <h2>定制化流程</h2>
          <p>可根据运营商公司内部结构定制多变的审批流程</p>
        </li>
        <li>
          <img src="../assets/maint-02-copy.png" alt="">
          <h2>可视化数据</h2>
          <p>灵活自主选择的抓取多维度统计数据</p>
        </li>
        <li>
          <img src="../assets/maint-03-copy.png" alt="">
          <h2>业务覆盖性</h2>
          <p>CAMP系统模板数据对接,维护其历史数据,对接FOC数据、财务系统账单数据</p>
        </li>
        <li>
          <img src="../assets/maint-04-copy.png" alt="">
          <h2>移动办公</h2>
          <p>支持PC、IPAD、手机等多电子设备操作随时随地信息化办公</p>
        </li>
        <li>
          <img src="../assets/maint-05-copy.png" alt="">
          <h2>无纸化办公</h2>
          <p>系统根据定制化流程,数据共享互通,形成流程闭环,无需任何线下操作</p>
        </li>
        <li>
          <img src="../assets/maint-06-copy.png" alt="">
          <h2>文件系统</h2>
          <p>独立权限,灵活配置管控,无需下载直接一键预览</p>
        </li>
      </ul>
    </div>

    <div class="itemTitle">
      <section-title style="margin-bottom: 40px;"
                     title="our projects"
                     sub-title="我们的功能"
      ></section-title>
      <!--            <Collapse v-model="value1">-->
      <!--                <Panel name="1">-->
      <!--                    功能一 7大子模块-->
      <!--                    <p slot="content">用数据可视化手段让业务处理流程更直观；对各类查询统计结果使用丰富的图形、图表进行直观展示用数据可视化手段让业务处理流程更直观；对各类查询统计结果使用丰富的图形、图表进行直观展处理流程更直观；对各类查询统计结果处理流程更直观；对各类查询统计结果处理流程更直观；对各类查询统计结果处理流程更直观；对各类查询统计结果处理流程更直观；对各类查询统计结果处理流程更直观；对各类查询统计结果处理流程更直观；对各类查询统计结果处理流程更直观；对各类查询统计结果示</p>-->
      <!--                </Panel>-->
      <!--                <Panel name="2">-->
      <!--                    功能二  7大子模块-->
      <!--                    <p slot="content">用数据可视化手段让业务处理流程更直观；对各类查询统计结果使用丰富的图形、图表进行直观展示用数据可视化手段让业务处理流程更直观；对各类查询统计结果使用丰富的图形、图表进行直观展示用数据可视化手段让业务处理流程更直观；对各类查询统计结果使用丰富的图形、图表进行直观展示</p>-->
      <!--                </Panel>-->
      <!--                <Panel name="3">-->
      <!--                    功能三  7大子模块-->
      <!--                    <p slot="content">可视化手段让业务处理流程更直观；对各类查询统计结果使用丰富的图处理流程更直观；对各类查询统计结果处理流程更直观；对各类查询统计结果处理流程更直观；对各类查询统计结果处理流程更直观；对各类查询统计结果处理流程更直观；对各类查询统计结果形、图表进行直观展示用数据可视化手段让业务处理流程可视化手段让业务处理流程更直观；对各类查询统计结果使用丰富的图形、图表进行直观展示用数据可视化手段让业务处理流程可视化手段让业务处理流程更直观；对各类查询统计结果使用丰富的图形、图表进行直观展示用数据可视化手段让业务处理流程</p>-->
      <!--                </Panel>-->
      <!--            </Collapse>-->
      <!--            <Banner :src="slowImg"></Banner>-->
      <img src="../assets/slow-icon.png" alt="" width="30" class="imgStyle" ><h2 class="titleStyle">生产管理流程图</h2>
      <div class="clear"></div>

      <div style="margin: 20px auto;width:100%">
        <img src="../assets/slow-02.jpg" style="width: 100%" alt="">
      </div>

      <!--            <Banner :src="slow2Img" style="padding-top:-80px;margin-top:-80px;"></Banner>-->
      <p  class="pTtilte">生产计划包括维修计划、定检计划和对外支援计划三种，可建立航材需求，计划建完之后，跳转到工作指令。生产计划包括维修计划、定检计划和对外支援计划三种，可建立航材需求、定检计划和对外支援计划三种，可建、定检计划和对外支援计划三种，可建，计划建完之后，跳转到工作指令。</p>
      <div class="lineStyle"></div>


      <img src="../assets/slow-icon.png" alt="" width="30" class="imgStyle" ><h2  class="titleStyle">工程管理流程图</h2>
      <div class="clear"></div>
      <div style="margin: 20px auto;width:100%">
        <img src="../assets/slow-03.jpg" style="width: 100%" alt="">
      </div>
      <!--            <Banner :src="slow3Img" style="margin-top: -60px"></Banner>-->
      <p  class="pTtilte">工程管理包括维修计划、定检计划和对外支援计划三种，可建立航材需求，计划建完之后，跳转到工作指令。生产计划包括维修计划、定检计划和对外支援计划三种，可建立航材需求、定检计划和对外支援计划三种，可建、定检计划和对外支援计划三种，可建，计划建完之后，跳转到工作指令。</p>
      <div  class="lineStyle"  ></div>

      <img src="../assets/slow-icon.png" alt="" width="30" class="imgStyle" ><h2  class="titleStyle">航材管理流程图</h2>
      <div class="clear"></div>
      <!--            <Banner :src="slow4Img" style="margin-top: -60px"></Banner>-->
      <div style="margin: 20px auto;width:100%">
        <img src="../assets/slow-04.jpg" style="width: 100%" alt="">
      </div>
      <p  class="pTtilte" >航材管理包括维修计划、定检计划和对外支援计划三种，可建立航材需求，计划建完之后，跳转到工作指令。生产计划包括维修计划、定检计划和对外支援计划三种，可建立航材需求、定检计划和对外支援计划三种，可建、定检计划和对外支援计划三种，可建，计划建完之后，跳转到工作指令。</p>
      <div   class="lineStyle" ></div>

      <img src="../assets/slow-icon.png" alt="" width="30" class="imgStyle" ><h2  class="titleStyle">质量管理流程图</h2>
      <div class="clear"></div>
      <!--            <Banner :src="slow5Img" style="margin-top: -60px"></Banner>-->
      <div style="margin: 20px auto;width:100%">
        <img src="../assets/slow-05.jpg" style="width: 100%" alt="">
      </div>
      <p  class="pTtilte">质量管理包括维修计划、定检计划和对外支援计划三种，可建立航材需求，计划建完之后，跳转到工作指令。生产计划包括维修计划、定检计划和对外支援计划三种，可建立航材需求、定检计划和对外支援计划三种，可建、定检计划和对外支援计划三种，可建，计划建完之后，跳转到工作指令。</p>
      <div  class="lineStyle"  ></div>

      <img src="../assets/slow-icon.png" alt="" width="30" class="imgStyle" ><h2  class="titleStyle">培训管理流程图</h2>
      <div class="clear"></div>
      <!--            <Banner :src="slow6Img" style="margin-top: -60px"></Banner>-->
      <div style="margin: 20px auto;width:100%">
        <img src="../assets/slow-06.jpg" style="width: 100%" alt="">
      </div>
      <p class="pTtilte">培训管理包括维修计划、定检计划和对外支援计划三种，可建立航材需求，计划建完之后，跳转到工作指令。生产计划包括维修计划、定检计划和对外支援计划三种，可建立航材需求、定检计划和对外支援计划三种，可建、定检计划和对外支援计划三种，可建，计划建完之后，跳转到工作指令。</p>
      <div   class="lineStyle"  ></div>

      <img src="../assets/slow-icon.png" alt="" width="30" class="imgStyle" ><h2  class="titleStyle">技术合同流程图</h2>
      <div class="clear"></div>
      <div style="margin: 20px auto;width:100%">
        <img src="../assets/slow-07.jpg" style="width: 100%" alt="">
      </div>
      <!--            <Banner :src="slow7Img" style="margin-top: -60px"></Banner>-->
      <p class="pTtilte">技术合同包括维修计划、定检计划和对外支援计划三种，可建立航材需求，计划建完之后，跳转到工作指令。生产计划包括维修计划、定检计划和对外支援计划三种，可建立航材需求、定检计划和对外支援计划三种，可建、定检计划和对外支援计划三种，可建，计划建完之后，跳转到工作指令。</p>
      <div   class="lineStyle"  ></div>

    </div>

    <Footer></Footer>
    <BackTop :height="100" :bottom="200">
      <div class="top">返回顶部</div>
    </BackTop>
  </div>

</template>
<script>
import Header from '../components/Header'
import Footer from '../components/Footer'
import Banner from '../components/Banner'
import SectionTitle from '../components/SectionTitle'
export default {
  name: 'Foc',
  components: {
    Header,
    Footer,
    Banner,
    SectionTitle
  },
  data(){
    return {
      bannerImg: require('../assets/banner-projects-05.jpg'),
      slowImg:  require('../assets/slow-01.jpg'),
      slow2Img: require('../assets/slow-02.jpg'),
      slow3Img: require('../assets/slow-03.jpg'),
      slow4Img: require('../assets/slow-04.jpg'),
      slow5Img: require('../assets/slow-05.jpg'),
      slow6Img: require('../assets/slow-06.jpg'),
      slow7Img: require('../assets/slow-07.jpg'),
      title:'智慧通航公务机机务维修平台',
      value1: '1'
    }
  }
}
</script>
<style lang="less" scoped>
h2 {
  /*color: #409eff;*/
  font-weight: bold;
  margin-bottom: 20px;
}
ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 30px;
  li {
    width: 31%;
    background: #f7f8fa;
    margin-bottom: 30px;
    text-align: center;
    padding: 30px;
    box-sizing: border-box;
    transition: all 0.5s;
    h2 {
      font-size: 18px;
      margin: 10px;
    }
    span {
      line-height: 30px;
      text-align: center;
      img {

      }
    }
    &:hover {
      background: #1a88c7;
      color: #fff;
    }
  }
}
/*.content {*/
/*    display: block;*/
/*    height: 1000px;*/
/*    margin: 0 auto;*/
/*    max-width: 1200px;*/
/*    h2 {*/
/*        margin-top: 30px;*/
/*        text-align: center;*/
/*    }*/
/*    .leftContent {*/
/*        width: 40%;*/
/*        float: left;*/
/*        p {*/
/*            text-indent:2em;*/
/*            text-align:justify;*/
/*            text-justify:inter-ideograph;*/
/*            line-height: 30px;*/
/*            img {*/
/*                max-width: 100%;*/
/*            }*/
/*            &:not(:last-child) {*/
/*                margin-bottom: 20px;*/
/*            }*/


/*        }*/
/*    }*/
/*    .rightContent{*/
/*        width: 60%;*/
/*        float: left;*/
/*    }*/

/*}*/

.about {
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px 0 60px;
  .compony {
    margin-top: 30px;
    display: flex;
    .img {
      flex: 1;
      img {
        max-width: 100%;
      }
    }
    .info {
      flex: 2;
      margin-left: 60px;
      h2 {
        margin-bottom: 20px;
      }
    }
  }
  .team {
    background: url(../assets/project-about-01.jpg) no-repeat right top;
    margin-top: 100px;
    overflow: hidden;
    .text {
      width: 60%;
      background: rgba(238, 238, 238, 0.6);
      padding: 30px;
      margin: 60px;
    }
  }
  p {
    line-height: 30px;
    margin-bottom: 10px;
  }
}
.itemTitle {
  max-width: 1200px;
  margin: 10px auto;
}
.top{
  padding: 10px;
  background: rgba(0, 153, 229, .7);
  color: #fff;
  text-align: center;
  border-radius: 2px;
}

/*流程图*/
.imgStyle{
  float: left;
  margin-right: 20px
}
.titleStyle {
  float:left
}
.lineStyle {
  width:100%;
  height: 1px;
  margin: 10px 0 30px 0;
  background: #e5e5e5;
}
.pTtilte {
  text-indent:2em;
  margin-top: 20px
}
.clear{
  clear: both;
}
</style>
