<template>
    <div class="banner">
        <img :src="src" alt=""/>
        <p>{{title}}</p>
        <span class="item">{{itemTitle}}</span>
        <Button ghost class="btn" :to="toUrl">{{defaultTitle}}</Button>
    </div>
</template>
<script>
export default {
    name: 'Banner',
    data(){
      return {
      }
    },
    props: {
        src: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        itemTitle: {
            type: String,
            default: ''
        },
        defaultTitle:{
            type: String,
            default: ''
        },
        toUrl:{
            type: String,
            default: ''
        }

    },
    watch: {
        src(){

        },
        title(){

        },
        itemTitle(){

        },
        defaultTitle(){

        },
        toUrl(){

        }
    },
    methods:{

    },
}
</script>
<style lang="less" scoped>
.banner {
    position: relative;
    img {
        padding-top: 80px;
        width: 100%;
        display: block;
    }
    p {
        position: absolute;
        color: #fff;
        font-size: 48px;
        font-weight: 900;
        left: 10%;
        top: 290px;
    }
    .item{
        position: absolute;
        color: #ebebeb;
        font-size: 16px;
        left: 10%;
        top: 370px;
    }
    .btn {
        position: absolute;
        font-size: 16px;
        left: 10%;
        top: 420px;
    }

}
</style>
