<template>
    <div class="banner">
        <img :src="src" alt=""/>
        <p :style="{color: color}" class="shadow">{{title}}</p>
    </div>
</template>
<script>
export default {
    name: 'Banner',
    data(){
      return {
      }
    },
    props: {
        src: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        color: {
            type: String,
            default: '#ffffff'
        },

    },
    watch: {
        src(){

        },
        title(){

        }
    }
}
</script>
<style lang="less" scoped>
.banner {
    position: relative;
    img {
        padding-top: 80px;
        display: block;
      width: 100%;
    }
    p {
        position: absolute;
        color: #fff;
        font-size: 36px;
        left: 200px;
        top: 200px;
    }
    .shadow {
        text-shadow:5px 2px 6px #000;
    }
}
</style>
