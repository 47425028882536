<template>
    <div>
        <Header   :num="'2'"></Header>
        <Banner :src="bannerImg" :title="title"></Banner>
        <div class="about">
            <div class="compony">
                <div class="img">
                    <img src="../assets/pic-04.jpg" alt="" />
                </div>
                <div class="info">
                    <h2>飞机监控系统</h2>
                    <p>
                        查看飞机相关信息基于FOS系统打造的一款移动端关信息基于FOS系统打造的一款移动端关信息基于FOS系统打造的一款移动端关信息基于FOS系统打造的一款移动端关信息基于FOS系统打造的一款移动端APP智能应用，让客户可通过手机随时查看飞机相关信息移动端APP智能应用，让客户可通过手机随时查看飞机相关信息基于FOS系统打造的一款移动端关信息基于FOS系统打造的一款移动端关信息基于FOS系统打造的一款移动端关信息基于FOS系统打造的一款移动端关信息基于FOS系统打造的一款移动端APP智能应用，让客户可通过手机随时查看飞机相关信息
                    </p>
                    <p>基于FOS系统打造的一款移动端APP智能应用，让客户可通过手机随时</p>

                </div>
            </div>

        </div>
        <section-title
                title="our projects"
                sub-title="我们的优势"
        ></section-title>

        <div class="itemTitle">
            <ul>
                <li>
                    <img src="../assets/icon_MV_01@2x.png" alt="">
                    <h2>架构延展性强</h2>
                    <p>系统架构设计轻量级、易用、实用、扩展能力强，能够满足业务广度和深度的扩展，我们的产品是标准化的，但是我们从不怕定制需求。</p>
                </li>
                <li>
                    <img src="../assets/icon_MV_02@2x.png" alt="">
                    <h2>业务覆盖性强</h2>
                    <p>业务覆盖航前、航中、航后全过程进行监控管理，既包含静态的基础数据存储、查询、统计</p>
                </li>
                <li>
                    <img src="../assets/icon_MV_02@2x.png" alt="">
                    <h2>可视化数据</h2>
                    <p>使用数据可视化手段让业务处理流程更直观；对各类查询统计结果使用丰富的图形、图表进行直观展示</p>
                </li>
                <li>
                    <img src="../assets/icon_MV_02@2x.png" alt="">
                    <h2>系统兼容性强</h2>
                    <p>使用数据可视化手段让业务处理流程更直观；对各类查询统计结果使用丰富的图形、图表进行直观展示</p>
                </li>
            </ul>
        </div>
        <Footer></Footer>

        <BackTop :height="100" :bottom="200">
            <div class="top">返回顶部</div>
        </BackTop>

    </div>

</template>
<script>
    import Header from '../components/Header'
    import Footer from '../components/Footer'
    import Banner from '../components/Banner'
    import SectionTitle from '../components/SectionTitle'
    export default {
        name: 'Foc',
        components: {
            Header,
            Footer,
            Banner,
            SectionTitle
        },
        data(){
            return {
                bannerImg: require('../assets/banner-projects-jiankong.jpg'),
                title:'飞行轨迹监控',
            }
        }
    }
</script>
<style lang="less" scoped>
    h2 {
        /*color: #409eff;*/
        font-weight: bold;
        margin-bottom: 20px;
    }
    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 30px;
        li {
            width: 24%;
            background: #f7f8fa;
            margin-bottom: 30px;
            text-align: center;
            padding: 30px;
            box-sizing: border-box;
            transition: all 0.5s;
            h2 {
                font-size: 18px;
                margin: 10px;
            }
            span {
                line-height: 30px;
                text-align: center;
                img {

                }
            }
            &:hover {
                background: #1a88c7;
                color: #fff;
            }
        }
    }
    /*.content {*/
    /*    display: block;*/
    /*    height: 1000px;*/
    /*    margin: 0 auto;*/
    /*    max-width: 1200px;*/
    /*    h2 {*/
    /*        margin-top: 30px;*/
    /*        text-align: center;*/
    /*    }*/
    /*    .leftContent {*/
    /*        width: 40%;*/
    /*        float: left;*/
    /*        p {*/
    /*            text-indent:2em;*/
    /*            text-align:justify;*/
    /*            text-justify:inter-ideograph;*/
    /*            line-height: 30px;*/
    /*            img {*/
    /*                max-width: 100%;*/
    /*            }*/
    /*            &:not(:last-child) {*/
    /*                margin-bottom: 20px;*/
    /*            }*/


    /*        }*/
    /*    }*/
    /*    .rightContent{*/
    /*        width: 60%;*/
    /*        float: left;*/
    /*    }*/

    /*}*/

    .about {
        max-width: 1200px;
        margin: 0 auto;
        padding: 30px 0 60px;
        .compony {
            margin-top: 30px;
            display: flex;
            .img {
                flex: 1;
                img {
                    max-width: 100%;
                }
            }
            .info {
                flex: 2;
                margin-left: 60px;
                h2 {
                    margin-bottom: 20px;
                }
            }
        }
        .team {
            background: url(../assets/project-about-01.jpg) no-repeat right top;
            margin-top: 100px;
            overflow: hidden;
            .text {
                width: 60%;
                background: rgba(238, 238, 238, 0.6);
                padding: 30px;
                margin: 60px;
            }
        }
        p {
            line-height: 30px;
            margin-bottom: 10px;
        }
    }



    .itemTitle {
        max-width: 1200px;
        margin: 0 auto;
    }
    .top{
        padding: 10px;
        background: rgba(0, 153, 229, .7);
        color: #fff;
        text-align: center;
        border-radius: 2px;
    }
</style>
