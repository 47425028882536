<template>
    <div>
        <Header></Header>
        <Banner :src="bannerImg"></Banner>
        <section-title title="hot news" sub-title="新闻动态"></section-title>
        <div class="news">
            <ul>
                <li v-for="item of news" :key="item.id">
                    <div class="img">
                        <img :src="item.img" />
                    </div>
                    <div class="info">
                        <h2>
                            <a href="/news/12">{{ item.title }}</a>
                        </h2>
                        <h3>{{ item.date }}</h3>
                        <p>{{ item.description }}</p>
                    </div>
                </li>
            </ul>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import Header from '../components/Header'
import Footer from '../components/Footer'
import Banner from '../components/Banner'
import SectionTitle from '../components/SectionTitle'

export default {
    name: 'News',
    components: {
        Header,
        Footer,
        Banner,
        SectionTitle
    },
    data() {
        return {
            bannerImg: require('../assets/banner-projects.jpg'),
            news: [
                {
                    img: require('../assets/news.jpg'),
                    title: '新闻标题的是否乐山大佛，苏打绿发是发',
                    date: '2020-03-03 12:12',
                    description:
                        '水电费利索打卡发斯蒂芬斯蒂芬了水电费卡士大夫，了水电费决胜巅峰水电费，水电费水电费水电费，苏打绿发接口是的防守打法，水电费水电费。水电费利索打卡发斯蒂芬斯蒂芬了水电费卡士大夫，了水电费决胜巅峰水电费，水电费水电费水电费，苏打绿发接口是的防守打法，水电费水电费。',
                    id: Math.floor(Math.random() * 10000)
                },
                {
                    img: require('../assets/news.jpg'),
                    title: '新闻标题的是否乐山大佛，苏打绿发是发',
                    date: '2020-03-03 12:12',
                    description:
                        '水电费利索打卡发斯蒂芬斯蒂芬了水电费卡士大夫，了水电费决胜巅峰水电费，水电费水电费水电费，苏打绿发接口是的防守打法，水电费水电费。',
                    id: Math.floor(Math.random() * 10000)
                },
                {
                    img: require('../assets/news.jpg'),
                    title: '新闻标题的是否乐山大佛，苏打绿发是发',
                    date: '2020-03-03 12:12',
                    description:
                        '水电费利索打卡发斯蒂芬斯蒂芬了水电费卡士大夫，了水电费决胜巅峰水电费，水电费水电费水电费，苏打绿发接口是的防守打法，水电费水电费。',
                    id: Math.floor(Math.random() * 10000)
                },
                {
                    img: require('../assets/news.jpg'),
                    title: '新闻标题的是否乐山大佛，苏打绿发是发',
                    date: '2020-03-03 12:12',
                    description:
                        '水电费利索打卡发斯蒂芬斯蒂芬了水电费卡士大夫，了水电费决胜巅峰水电费，水电费水电费水电费，苏打绿发接口是的防守打法，水电费水电费。',
                    id: Math.floor(Math.random() * 10000)
                },
                {
                    img: require('../assets/news.jpg'),
                    title: '新闻标题的是否乐山大佛，苏打绿发是发',
                    date: '2020-03-03 12:12',
                    description:
                        '水电费利索打卡发斯蒂芬斯蒂芬了水电费卡士大夫，了水电费决胜巅峰水电费，水电费水电费水电费，苏打绿发接口是的防守打法，水电费水电费。',
                    id: Math.floor(Math.random() * 10000)
                },
                {
                    img: require('../assets/news.jpg'),
                    title: '新闻标题的是否乐山大佛，苏打绿发是发',
                    date: '2020-03-03 12:12',
                    description:
                        '水电费利索打卡发斯蒂芬斯蒂芬了水电费卡士大夫，了水电费决胜巅峰水电费，水电费水电费水电费，苏打绿发接口是的防守打法，水电费水电费。',
                    id: Math.floor(Math.random() * 10000)
                }
            ]
        }
    }
}
</script>
<style lang="less" scoped>
.news {
    max-width: 1200px;
    margin: 0 auto;
    ul {
        li {
            display: flex;
            margin-bottom: 30px;
            .img {
                margin-right: 20px;
                width: 220px;
                img {
                    max-width: 100%;
                }
            }
            .info {
                flex: 1;
                h2 {
                    margin-bottom: 10px;
                }
                h3 {
                    color: #999;
                    margin-bottom: 10px;
                }
            }
            p {
                line-height: 22px;
                color: #666;
            }
        }
    }
}
</style>
