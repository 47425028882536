<template>
    <div class="home">
        <Header :num="'1'"></Header>
<!--        <Banner :src="airplaneBg"></Banner>-->

        <!--轮播图-->
        <Carousel  v-model="value"
                  :autoplay="setting.autoplay"
                  :autoplay-speed="setting.autoplaySpeed"
                  :dots="setting.dots"
                  :radius-dot="setting.radiusDot"
                  :trigger="setting.trigger"
                  :arrow="setting.arrow"
        >
            <CarouselItem>
                <div class="demo-carousel"><BannerScrop :title="title1" :itemTitle="itemTitle1" :defaultTitle="defaultTitle1" :toUrl="'/about'" :src="airplaneBg"></BannerScrop></div>
            </CarouselItem>
            <CarouselItem>
                <div class="demo-carousel"><BannerScrop :title="title2" :itemTitle="itemTitle2" :defaultTitle="defaultTitle2" :toUrl="'/foc'"  :src="airplaneBg02"></BannerScrop></div>
            </CarouselItem>
            <CarouselItem>
                <div class="demo-carousel"><BannerScrop :title="title3" :itemTitle="itemTitle3" :defaultTitle="defaultTitle3" :toUrl="'/serviceSoft'"   :src="airplaneBg04"></BannerScrop></div>
            </CarouselItem>
            <CarouselItem>
                <div class="demo-carousel"><BannerScrop  :title="title4" :itemTitle="itemTitle4" :defaultTitle="defaultTitle4" :toUrl="'/repair'"    :src="airplaneBg03"></BannerScrop></div>
            </CarouselItem>
            <CarouselItem>
                <div class="demo-carousel"><BannerScrop   :title="title5" :itemTitle="itemTitle5" :defaultTitle="defaultTitle5" :toUrl="'/service'"    :src="airplaneBg05"></BannerScrop></div>
            </CarouselItem>
        </Carousel>


        <!--我们的服务-->
        <div class="service">
            <section-title
                title="our service"
                sub-title="我们的服务"
            ></section-title>
            <ul>
                <li v-for="item of services" :key="item.title">
                    <img :src="item.svg" width="50px"/>
                    <h3>{{ item.title }}</h3>
                    <h4>{{ item.subTitle }}</h4>
                    <p>{{ item.comment }}</p>
                </li>
            </ul>
        </div>



        <!--项目展示-->
        <div class="projects">
            <section-title
                title="our projects"
                sub-title="项目展示"
                :white="true"
            ></section-title>
            <ul>
                <li  v-for="item of projects" :key="item.title">

                    <img :src="item.icon" />
                    <h3  style="font-weight: bold">{{ item.title }}</h3>
                    <h4>{{ item.subTitle }}</h4>
                    <p>{{ item.comment }}</p>
                </li>
            </ul>
            <ul style="margin-top: 20px">
                <li v-for="item of projectsSecond" :key="item.title">
                    <img :src="item.icon" />
                    <h3 style="font-weight: bold">{{ item.title }}</h3>
                    <h4>{{ item.subTitle }}</h4>
                    <p>{{ item.comment }}</p>
                </li>
            </ul>
        </div>

        <!--智慧通航-->
        <div>
            <section class="passAirport">
                <div class="contentCneter">
                    <h2>智慧通航</h2>
                    <div class="titleContent">
                        <p>智慧通航公务机云平台整体架构包含六大平台和四大应用系统，六大平台的组成了一个大数据底座服务，其中包含基础支撑、数据采集、数据分析等，基于六大平台基础之上，可以根据实际行业环境和业务需求动态扩展不同的应用系统，极大的提高了云平台的可扩展性，最终打造成为一个全系统、全数据整合的智慧通航公务机云平台。</p>
                    </div>
                    <div style="width: 100%;height: 1px;background-color: #ffffff;margin-top: 40px;">

                    </div>
                    <div class="more">
                        <span @click="toProject" style="float:left">了解更多 </span>
                        <img  @click="toProject"  src="../assets/right-arrow-white.png" width="20px" style="float: right" alt="">

                    </div>
                </div>
            </section>
        </div>

        <!--联系我们-->
        <div>
            <section class="passAirport02">
                <div class="contentCneter">
                    <h2>关于我们</h2>
                    <div class="titleContent">
                        <p>立足新起点，开创新局面</p>
                    </div>
                    <div style="width: 100%;height: 1px;background-color: #ffffff;margin-top: 40px;">

                    </div>
                    <div class="more">
                        <span @click="toContact" style="float:left">了解更多 </span>
                        <img @click="toContact" src="../assets/right-arrow-white.png" width="20px" style="float: right" alt="">
                    </div>
                </div>
            </section>
        </div>

        <!--关于新展-->
        <div class="about">
            <section-title
                title="about genway"
                sub-title="关于新展"
            ></section-title>
            <div class="container">
                <div class="text">
                    <p style="font-size:16px;">
                        <b>湖南新展信息技术有限公司</b
                        >，主要致力于智慧通航领域，为客户实现定制化、高效率，前-中-后台一体化的智慧通航公务机生产运行管理云平台。帮助客户定义标准，打破信息孤岛，凭借先进、专业的信息化软件，迅速、明智、自信地实施决策和采取行动，通过精细化管理为客户带来全新的体验和商业契机。
                    </p>
                    <p style="font-size: 16px;">
                      公司技术核心团队是由一支专业的行业精英组成，本着服务至上的宗旨，高质高效的目标产出，力求为合作企业解决各种技术创新难题，通过深度合作实现双赢，为企业双方在互联网+时代开拓一片新的疆土，抢占市场先机，提升企业核心竞争力与价值。
                    </p>
                  <p style="font-size: 16px;">
                    目前经过我们公司开发的产品项目涵盖了互联网、公务机、智慧城市、电商、金融等各大行业。服务对象包含了个人、企业、事业单位、电信运营商等。涉及的终端类型包含了移动APP端、Web端、大屏、微信公众号等。
                  </p>
                    <div style="width: 100%;height: 1px;background-color: #409eff;margin-top: 70px;">

                    </div>
                    <div class="more">
                        <span @click="toAbout" style="float:left">了解更多 </span>
                        <img  @click="toAbout"  src="../assets/right-arrow.png" width="20px" style="float: right" alt="">

                    </div>
                </div>
<!--                <div class="img">-->
<!--                    <img src="../assets/project.jpg" />-->
<!--                </div>-->
            </div>
        </div>

        <!--新闻暂时去掉-->
<!--        <div class="split"></div>-->
<!--        <div class="news">-->
<!--            <section-title-->
<!--                title="hot news"-->
<!--                sub-title="新闻动态"-->
<!--            ></section-title>-->
<!--            <ul>-->
<!--                <li>-->
<!--                    <div class="img">-->
<!--                        <img src="../assets/news.jpg" />-->
<!--                    </div>-->
<!--                    <div class="info">-->
<!--                        <h2>-->
<!--                            新闻标题新闻标题新闻标题题-->
<!--                        </h2>-->
<!--                        <h3>2020-03-04</h3>-->
<!--                        <p>-->
<!--                            新闻内容是劳动法健身房，是逢狼时刻的防守打法，是；的防守打法水电费，新闻内容是劳动法健身房，是逢狼时刻的防守-->
<!--                        </p>-->
<!--                    </div>-->
<!--                </li>-->
<!--                <li>-->
<!--                    <div class="img">-->
<!--                        <img src="../assets/news.jpg" />-->
<!--                    </div>-->
<!--                    <div class="info">-->
<!--                        <h2>-->
<!--                            新闻标题新闻标题新闻标题新-->
<!--                        </h2>-->
<!--                        <h3>2020-03-04</h3>-->
<!--                        <p>-->
<!--                            新闻内容是劳动法健身房，是逢狼时刻的防守打法，是；的防守打法水电费，新闻内容是劳动法健身房，是逢狼时刻的防守打法-->
<!--                        </p>-->
<!--                    </div>-->
<!--                </li>-->
<!--                <li>-->
<!--                    <div class="img">-->
<!--                        <img src="../assets/news.jpg" />-->
<!--                    </div>-->
<!--                    <div class="info">-->
<!--                        <h2>-->
<!--                            新闻标题新闻标题新闻标题新闻标题-->
<!--                        </h2>-->
<!--                        <h3>2020-03-04</h3>-->
<!--                        <p>-->
<!--                            新闻内容是劳动法健身房，是逢狼时刻的防守打法，是；的防守打法水电费，新闻内容是劳动法健身房，是逢狼时刻的防守打法-->
<!--                        </p>-->
<!--                    </div>-->
<!--                </li>-->
<!--                <li>-->
<!--                    <div class="img">-->
<!--                        <img src="../assets/news.jpg" />-->
<!--                    </div>-->
<!--                    <div class="info">-->
<!--                        <h2>-->
<!--                            新闻标题新闻标题新闻标题新闻标-->
<!--                        </h2>-->
<!--                        <h3>2020-03-04</h3>-->
<!--                        <p>-->
<!--                            新闻内容是劳动法健身房，是逢狼时刻的防守打法，是；的防守打法水电费，新闻内容是劳动法健身房，是逢狼时刻的防守打法s-->
<!--                        </p>-->
<!--                    </div>-->
<!--                </li>-->
<!--            </ul>-->
<!--        </div>-->

<!--        <div style="margin-left: 94%">-->
<!--            <Affix :offset-bottom="80" style="">-->
<!--                <ButtonGroup vertical size="large">-->
<!--                    <Button type="primary" icon="logo-facebook"></Button>-->
<!--                    <Button  type="primary" icon="logo-twitter"></Button>-->
<!--                    <Button  type="primary"  icon="logo-googleplus"></Button>-->
<!--                    <Button  type="primary" icon="logo-tumblr"></Button>-->
<!--                </ButtonGroup>-->
<!--            </Affix>-->
<!--        </div>-->

        <Footer></Footer>
        <BackTop :height="100" :bottom="200">
            <div class="top">返回顶部</div>
        </BackTop>

    </div>
</template>

<script>
// @ is an alias to /src
import Header from '../components/Header'
import SectionTitle from '../components/SectionTitle'
import Footer from '../components/Footer'
import BannerScrop from '../components/BannerScrop'

    // $(function () {
    //     alert('引入成功')
    // })
export default {

    name: 'Home',
    components: {
        Header,
        SectionTitle,
        Footer,
        BannerScrop
    },
    data() {
        return {
            value: 0,
            setting: {
                autoplay: true,
                autoplaySpeed: 5000,
                dots: 'inside',
                radiusDot: false,
                trigger: 'click',
                arrow: 'hover'
            },
            title1:'立足新起点，开创新局面',
            itemTitle1:'湖南新展信息技术有限公司，主要致力于面向中小型企业提供定制化产品研发与服务。',
            defaultTitle1:'关于我们',
            title2:'智慧通航运行控制平台',
            itemTitle2:'打造智慧通航的时代，专注于为通用航空领域',
            defaultTitle2:'查看更多',
            title3:'您最贴心的软件定制管家',
            itemTitle3:'业务覆盖平台： Android、IOS、Web App 网站、微信小程序、微信公共号、大数据平台',
            defaultTitle3:'联系我们',
            title4:'维修管理平台',
            itemTitle4:'一整套的飞机维修控制、生产管理、航材管理、工程管理、质量管理及技术合同的网格化系统平台',
            defaultTitle4:'查看更多',
            title5:'定制化软件，随心所欲',
            itemTitle5:'无论软件产品大小，一律按需定制。',
            defaultTitle5:'查看更多',

            services: [
                {

                    svg: require('../assets/service-pc.svg'),
                    icon: require('../assets/jet.png'),
                    title: 'PC端定制开发',
                    subTitle: 'SoftwareDevelopment',
                    comment:
                        '企业管理系统/互联网金融系统/电商平台/公司官网/大数据'
                },
                {
                    // svg: require('../assets/service-soft.svg'),
                    svg: require('../assets/jet.png'),
                    title: '软件定制开发',
                    subTitle: 'SoftwareDevelopment',
                    comment:
                        '专注核心竞争力业务，降低软件项目成本，解决雇佣技术人员或者无法管理技术人员的难题'
                },
                {
                    svg: require('../assets/service-mobile.svg'),
                    icon: require('../assets/jet.png'),
                    title: '移动端定制开发',
                    subTitle: 'Software Development',
                    comment:
                        'APP开发/微信小程序/微信公众号/品牌推广APP活动策划/手机游戏'
                }
            ],
            projects: [
                {
                    icon: require('../assets/project-home-01.jpg'),
                    title: '公务机运行云平台产品',
                    subTitle: 'JET OPERATION',
                    comment:
                        '商务管理、客服管理、飞行管理、运行管理、客舱管理、机务管理、质量管理、证件管理、统计管理。'
                },
                {
                    icon: require('../assets/project-home-03.jpg'),
                    title: '公务机维修平台案例',
                    subTitle: 'AMBER MAINT MANAGER',
                    comment:
                        '针对于国内通航企业开发的一整套的飞机维修控制、生产管理、航材管理、工程管理、质量管理及技术合同的网格化系统平台。'
                },
                {
                    icon: require('../assets/project-home-02.jpg'),
                    title: '飞行轨迹监控产品',
                    subTitle: 'FLIGHT MONITOR',
                    comment:
                        '通过解析飞机坐标定位信息，结合地图，实时监控和展示公司各架飞机的飞行状态和位置状态，实现了航线动态监控的便捷化、多元化。'
                },


            ],
            projectsSecond:[
                {
                    icon: require('../assets/project-home-04.jpg'),
                    title: '高端商务包机平台案例',
                    subTitle: 'King Leader',
                    comment:
                        '特惠包机、定制包机、高端旅游、高端产品、小时卡等。'
                },
                {
                    icon: require('../assets/project-home-05.jpg'),
                    title: '唯您APP案例',
                    subTitle: 'App Software',
                    comment:
                        '基于FOS系统打造的一款移动端APP智能应用，让客户可随时查看飞机信息，查询过往的飞机运行和财务数据，监控飞机实时状态。'
                },
                {
                    icon: require('../assets/project-home-06.jpg'),
                    title: '金融类案例',
                    subTitle: 'Financial Software',
                    comment:
                        '为金融合作伙伴提供互联网金融产品；联合运营，商户推荐等；系统包含K线分析、行情监控、多空对比等'
                },
            ],

            bannerImg: require('../assets/banner-home.jpg'),
            airplaneBg:require('../assets/airplane-bg.jpg'),
            airplaneBg02: require('../assets/banner-02-copy.jpg'),
            airplaneBg03: require('../assets/banner-03-copy.jpg'),
            airplaneBg04: require('../assets/banner-04.jpg'),
            airplaneBg05: require('../assets/banner-05.jpg'),
        }
    },
    methods: {
        toAbout() {
            this.$router.push('/about')
        },
        toProject(){
            this.$router.push('/foc')
        },
        toContact(){
            this.$router.push('/about')
        }
    },
    mounted() {},


}
</script>

<style lang="less" scoped>
.service {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 30px 0 60px;
    ul {
        display: flex;
        li {
            flex: 1;
            &:nth-child(2) {
                margin: 0 100px;
            }
            text-align: center;
            h3 {
                font-size: 16px;
                margin-bottom: 10px;
            }
            h4 {
                font-size: 14px;
                color: #999;
                margin-bottom: 30px;
            }
            p {
                color: #666;
                line-height: 30px;
                font-size: 14px;
            }
        }
    }
}
.projects {
    background: url('../assets/project_bg.jpg') no-repeat top center;
    overflow: hidden;
    .section-title {
        margin: 80px 0;
    }
    ul {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        li {
            width: 30%;
            flex-basis: 30%;
            background: #f2f0f1;
            img {
                width: 100%;
                height: 218px;
            }
            h3 {
                color: #454545;
                font-size: 18px;
                padding: 0 30px;
                margin-bottom: 10px;
                margin-top: 30px;
            }
            h4 {
                color: #999;
                padding: 0 30px;
                margin-bottom: 30px;
                font-size: 16px;
            }
            p {
                padding: 0 30px;
                color: #666;
                margin-bottom: 30px;
                font-size: 14px;
                line-height: 30px;
            }
        }
    }
}
.about {
    padding: 30px 0 60px;
    .container {
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        .text {
            flex: 2;
            margin-right: 60px;
            line-height: 30px;
            p {
                margin-bottom: 30px;
                text-indent: 28px;
            }
            b {
                /*color: #409eff;*/
            }
        }
        .img {
            flex: 1;
            img {
                max-width: 100%;
            }
        }
        .more {
            margin-top: 20px;
            text-align: left;
            span {
                /*color: #f08519;*/
                font-size: 14px;
                cursor: pointer;
                color: #409eff;
                font-weight: bold;
                &:hover {
                    color: #000000;
                }
            }
            img {
                &:hover {
                    cursor: pointer;
                    transform: translateX(-10px);
                }
            }
        }
    }
}
.split {
    background: #409eff;
    height: 10px;
}
.news {
    padding: 30px 0;

    ul {
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        li {
            width: 48%;
            display: flex;
            margin-bottom: 30px;

            .img {
                margin-right: 20px;
            }

            .info {
                h2 {
                    font-size: 14px;
                    margin-bottom: 20px;
                }

                h3 {
                    font-size: 12px;
                    color: #999;
                    margin-bottom: 10px;
                }
            }

            p {
                line-height: 22px;
                color: #666;
            }
        }
    }

}

.passAirport {
    margin-top: 60px;
    width: 100%;
    height: 300px;
    background: url('../assets/banner-home.jpg') no-repeat;
    background-size: 100% 100%;
}
.passAirport02 {
    margin-top: 2px;
    width: 100%;
    height: 300px;
    background: url('../assets/banner-home-contact.jpg') no-repeat;
    background-size: 100% 100%;
}
.contentCneter {
    margin: 0 auto;
    max-width: 1200px;
    h2 {
        font-size: 24px;
        font-weight: bold;
        padding-top: 80px;
        color: #fff;
    }
    .titleContent{
        padding: 14px 0;
        p {
            font-size: 14px;
            color: #fff;
        }
    }
    .more {
        margin-top: 14px;
        span{
            color: #fff;
            /*font-size: 16px;*/
            &:hover {
                cursor: pointer;
                color: #409eff;
                /*font-size: 16px;*/
            }

        }
        img {
            &:hover {
                cursor: pointer;
                transform: translateX(-10px);
            }
        }
        /*&:hover {*/
        /*    cursor: pointer;*/
        /*    span {*/
        /*        color: #409eff;*/
        /*        font-size: 16px;*/
        /*    }*/
        /*    img {*/
        /*        transform: translateX(-10px);*/
        /*    }*/
        /*}*/

    }
}

/deep/ .ivu-carousel-dots {
    li {
        button {
            background: #fff;
        }
    }
}

.top{
    padding: 10px;
    background: rgba(0, 153, 229, .7);
    color: #fff;
    text-align: center;
    border-radius: 2px;
}
</style>
