<template>
    <div class="footer">
        <div class="info">
            <div class="logo">
                <img style="margin-left: -18px;" src="../assets/logo-white.png" />
                <p>联系电话(长沙): +86 0731-82297876</p>
                <p>公司地址(长沙): 湖南省长沙市岳麓区西中心T2-1610室</p>
                <p>联系电话(香港): +852 22163940</p>
                <p>公司地址(香港): 香港天后英皇道25號景星中心16樓</p>
            </div>
            <ul>
                <li @click="navTo('/')">首页</li>
                <li @click="navTo('/projects')">智慧通航</li>
                <li @click="navTo('/service')">软件服务</li>
                <li @click="navTo('/about')">关于我们</li>
<!--                <li @click="navTo('/contact')">联系我们</li>-->
            </ul>
        </div>
        <div class="copyright">
            <div class="container">
                <div>Copyright @ 2018~2023 All Rights Reserved 湖南新展信息技术有限公司</div>
                <div style="cursor: pointer;" @click="goPage()">
                  <template v-if="sourceUrl.indexOf('gneway.cn') != -1">
                    湘ICP备18018646号
                  </template>
                  <template v-else>
                    湘ICP备18018646号
                  </template>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Footer',
    data() {
      return {
        sourceUrl: ''
      }
    },
    created() {
      this.sourceUrl = window.location.href;
    },
    methods: {
        navTo(link) {
            this.$router.push(link);
            this.scrollGo(1);
        },
        scrollGo(sTop) {
            const step = Math.ceil(sTop / 1000 * 50);
            function scroll(start, end, step) {
                if (start === end) {
                    return;
                }
                let d = (start + step > end) ? end : start + step;
                if (start > end) {
                    d = (start - step < end) ? end : start - step;
                }

                window.scrollTo(0, d);
                window.requestAnimationFrame(() => scroll(d, end, step));
            }
            scroll(0, sTop, step);
        },
        goPage() {
            window.open('https://beian.miit.gov.cn')
        }
    }
}
</script>
<style lang="less" scoped>
.footer {
    background: #3e3c3d;
    .logo {
        /*color: #fff;*/
        color: #b4b4b4;
        p {
            margin-top: 20px;
        }
    }
    .info {
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 60px;
        font-size: 14px;
        ul {
            display: flex;
            li {
                color: #b4b4b4;
                cursor: pointer;
                margin-left: 30px;
                &:hover {
                    color: #409eff;
                }
            }
        }
    }
    .copyright {
        background: #323031;
        .container {
            display: flex;
            justify-content: space-between;
            color: #b4b4b4;
            height: 80px;
            align-items: center;
            max-width: 1200px;
            margin: 0 auto;
        }
    }
}
</style>
