<template>
    <div>
        <Header   :num="'2'"></Header>
        <Banner :src="bannerImg" :title="title"></Banner>
        <section-title
            title="our projects"
            sub-title="智慧通航行业案例"
        ></section-title>
        <div class="content">
            <p>目前经过我们公司开发的公务航空行业领域产品项目包含：</p>
<!--            <p>1.唯您APP-基于FOS系统打造的一款移动端APP智能应用，让客户可通过手机随时查看飞机相关信息，查询过往的飞机运行和财务数据，监控飞机实时状态（包括飞行轨迹、行程计划申请进度、机组准备情况、正在进行的维修工作等）</p>-->
<!--            <p>2.查询过往的飞机运行和财务数据，监控飞机实时状态（包括飞行轨迹、行程计划申请进度、机组准备情况、正在进行的维修工作等）</p>-->
<!--            <p> 3.智慧通航运行控制平台-商务管理、客服管理、飞行管理、运行管理、客舱管理、机务管理、质量管理、证件管理、统计管理</p>-->


            <p>1.<span style="font-weight: bold;">唯您APP</span> —— 基于FOS系统打造的一款移动端APP智能应用，让客户可通过手机随时查看飞机相关信息，查询过往的飞机运行和财务数据，监控飞机实时状态（包括飞行轨迹、行程计划申请进度、机组准备情况、正在进行的维修工作等）。</p>
            <p>2.<span style="font-weight: bold;" >众飞飞行支援中心</span> ——  在线提交PO需求，包含飞行批复、酒店、地服&燃油等，迷你版运行管理平台，包含飞行计划、飞行日志、机队管理、机组管理、客人管理、民航表单等。
            </p>
            <p> 3.<span style="font-weight: bold;">公务机智维平台</span> ——  针对于国内通航企业开发的一整套的飞机维修控制、生产管理、航材管理、工程管理、质量管理及技术合同的网格化系统平台。
            </p>
            <p>
                4.<span style="font-weight: bold;">高端商务包机平台</span> —— 特惠包机、定制包机、高端旅游、高端产品、小时卡等。
            </p>
            <p>
                5.<span style="font-weight: bold;">智慧通航公务机云平台</span> —— 商务管理、客服管理、飞行管理、运行管理、客舱管理、机务管理、质量管理、证件管理、统计管理。
            </p>
            <ul>
                <li v-for="item of services" :key="item.id">
                <span>
                    <img :src="item.icon" />
                </span>
                    <h2>{{ item.title }}</h2>
                    <h3>{{ item.subTitle }}</h3>
                    <p>{{ item.comments }}</p>
                </li>
            </ul>
        </div>

        <div>
            <section class="passAirport">
                <div class="contentCneter">
                    <h2>公务机运行平台</h2>
                    <div class="titleContent">
                        <p>智慧通航公务机云平台整体架构包含六大平台和四大应用系统</p>
                    </div>
                    <div style="width: 100%;height: 1px;background-color: #ffffff;margin-top: 40px;">
                    </div>
                    <div class="more">
                        <span @click="toFoc" style="float:left">了解更多 </span>
                        <img @click="toFoc" src="../assets/right-arrow-white.png" width="20px" style="float: right" alt="">
                    </div>
                </div>
            </section>
        </div>

<!--        <div>-->
<!--            <section class="passAirport2">-->
<!--                <div class="contentCneter">-->
<!--                    <h2>飞行轨迹监控</h2>-->
<!--                    <div class="titleContent">-->
<!--                        <p>飞机轨迹监控平台，监控飞机实时状态（包括飞行轨）</p>-->
<!--                    </div>-->
<!--                    <div style="width: 100%;height: 1px;background-color: #ffffff;margin-top: 40px;">-->
<!--                    </div>-->
<!--                    <div class="more">-->
<!--                        <span @click="toRepair" style="float:left">了解更多 </span>-->
<!--                        <img @click="toRepair" src="../assets/right-arrow-white.png" width="20px" style="float: right" alt="">-->
<!--                    </div>-->
<!--                </div>-->
<!--            </section>-->
<!--        </div>-->

        <div>
            <section class="passAirport3">
                <div class="contentCneter">
                    <h2>公务机维修平台</h2>
                    <div class="titleContent">
                        <p>维修管理平台根据公务机运营商机务维修部门工作操作流程,将业务划分为 工程管理子系统、生产计划与控制子系统、航材工具管理子系统、 质量管理子系统、SMS安全管理子系统、培训管理子系统、技术合同管理子系统、系统基础管理子系统;</p>
                    </div>
                    <div style="width: 100%;height: 1px;background-color: #ffffff;margin-top: 40px;">
                    </div>
                    <div class="more">
                        <span @click="toTrajectory" style="float:left">了解更多 </span>
                        <img @click="toTrajectory" src="../assets/right-arrow-white.png" width="20px" style="float: right" alt="">
                    </div>
                </div>
            </section>
        </div>

<!--        <div style="margin-left: 94%">-->
<!--            <Affix :offset-bottom="80" style="">-->
<!--                <ButtonGroup vertical size="large">-->
<!--                    <Button type="primary" icon="logo-facebook"></Button>-->
<!--                    <Button  type="primary" icon="logo-twitter"></Button>-->
<!--                    <Button  type="primary"  icon="logo-googleplus"></Button>-->
<!--                    <Button  type="primary" icon="logo-tumblr"></Button>-->
<!--                </ButtonGroup>-->
<!--            </Affix>-->
<!--        </div>-->
        <Footer></Footer>

        <BackTop :height="100" :bottom="200">
            <div class="top">返回顶部</div>
        </BackTop>


    </div>
</template>
<script>
import Header from '../components/Header'
import Footer from '../components/Footer'
import Banner from '../components/Banner'
import SectionTitle from '../components/SectionTitle'

export default {
    name: 'Projects',
    components: {
        Header,
        Footer,
        Banner,
        SectionTitle
    },
    data() {
        return {
            bannerImg: require('../assets/banner-projects.jpg'),
            activeNav: 'foc',
            title:'通航项目',
            services: [
                {
                    icon: require('../assets/icon_MV_01@2x.png'),
                    title: '全新运行系统',
                    id: Math.floor(Math.random() * 10000),
                    comments:
                        '基于FOS系统打造的一款移动端APP智能应用，让客户可通过手机随时查看飞机相关信息，行程计划申请进度、机组准备情况、正在进行的维修工作等）'
                },
                {
                    icon: require('../assets/icon_MV_02@2x.png'),
                    title: '实时监控',
                    id: Math.floor(Math.random() * 10000),
                    comments:
                        '打造的一款移动端智能应用，让客户可通过手机随时查看计划申请进度、机组准备情况、正在进行的维修工作等）'
                },
                {
                    icon: require('../assets/icon_MV_03@2x.png'),
                    title: '操作简单',
                    id: Math.floor(Math.random() * 10000),
                    comments: '智能化的FOC运行系统，只需几步，就能轻松实现飞机运行操作数据更新'
                },

            ]
        }
    },
    methods: {
        navTo(link) {
            this.$router.push(link)
            this.activeNav = link.split('/')[2]
        },
        toFoc(){
            this.$router.push('/foc')
        },
        toRepair(){
            this.$router.push('/trajectory')
        },
        toTrajectory(){
            this.$router.push('/repair')
        },

    }
}
</script>
<style lang="less" scoped>
.projects {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    padding: 30px 0 60px;
    .nav {
        width: 200px;
        li {
            cursor: pointer;
            font-size: 16px;
            padding: 20px;
            box-sizing: border-box;
            &.active {
                border-left: 1px solid #409eff;
                border-bottom: 1px solid #409eff;
            }
        }
    }
    .container {
        flex: 1;
        margin-left: 60px;
    }
}

.passAirport {
    margin-top: 30px;
    width: 100%;
    height: 300px;
    background: url('../assets/pic-02.jpg') no-repeat;
    background-size: 100% 100%;
}
.passAirport2 {
    margin-top: 4px;
    width: 100%;
    height: 300px;
    background: url('../assets/pic-01.jpg') no-repeat;
    background-size: 100% 100%;
}
.passAirport3 {
    margin: 4px 0;
    width: 100%;
    height: 300px;
    background: url('../assets/pic-03.jpg') no-repeat;
    background-size: 100% 100%;
}
.contentCneter {
    margin: 0 auto;
    max-width: 1200px;
    h2 {
        font-size: 24px;
        font-weight: bold;
        padding-top: 80px;
        color: #fff;
    }
    .titleContent{
        padding: 14px 0;
        p {
            font-size: 14px;
            color: #fff;
        }
    }
    .more {
        margin-top: 14px;
        span{
            color: #fff;
            /*font-size: 16px;*/
            &:hover {
                cursor: pointer;
                color: #409eff;
                /*font-size: 16px;*/
            }

        }
        img {
            &:hover {
                cursor: pointer;
                transform: translateX(-10px);
            }
        }

    }
}

.content {
    margin: 0 auto;
    max-width: 1200px;
    p {
        text-indent:2em;
        text-align:justify;
        text-justify:inter-ideograph;
        line-height: 30px;
        img {
            max-width: 100%;
        }
        &:not(:last-child) {
            margin-bottom: 20px;
        }


    }
    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 40px;
        li {
            width: 31%;
            background: #f7f8fa;
            margin-bottom: 30px;
            text-align: center;
            padding: 30px;
            box-sizing: border-box;
            transition: all 0.5s;
            h2 {
                font-size: 18px;
                margin: 10px;
            }
            span {
                line-height: 30px;
                text-align: center;
                img {

                }
            }
            &:hover {
                background: #1a88c7;
                color: #fff;
            }
        }
    }
}

.top{
    padding: 10px;
    background: rgba(0, 153, 229, .7);
    color: #fff;
    text-align: center;
    border-radius: 2px;
}

</style>
