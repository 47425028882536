<template>
    <div class="section-title" :class="whiteTheme">
        <div class="container">
            <h2>{{ resultTitle }}</h2>
            <p>{{ subTitle }}</p>
        </div>
    </div>
</template>
<script>
export default {
    name: 'SectionTitle',
    data() {
        return {
            name: 'dddd'
        }
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        subTitle: {
            type: String,
            default: ''
        },
        white: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        resultTitle() {
            return this.title.toUpperCase()
        },
        whiteTheme() {
            return this.white ? 'white-theme' : ''
        }
    },
    methods: {}
}
</script>
<style lang="less" scoped>
.section-title {
    padding: 30px 0;
    .container {
        max-width: 200px;
        margin: 0 auto;
        text-align: center;
    }
    h2 {
        font-size: 22px;
        margin-bottom: 10px;
    }
    p {
        font-size: 16px;
        display: block;
        position: relative;
        &::before,
        &::after {
            content: '';
            position: absolute;
            width: 100px;
            height: 1px;
            background: #ccc;
            top: 50%;
        }
        &::before {
            left: -80px;
        }
        &::after {
            right: -80px;
        }
    }
    &.white-theme {
        h2 {
            color: #fff;
        }
        p {
            color: #fff;
            &::before,
            &::after {
                background: #fff;
            }
        }
    }
}
</style>
