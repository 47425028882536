<template>
    <div>
        <Header   :num="'2'"></Header>
        <Banner :src="bannerImg" :title="title"></Banner>




        <div class="about">

            <section-title
                    title="our architecture"
                    sub-title="我们的架构"
            ></section-title>
                        <div style="margin: 0 auto;max-width:1200px">
                            <div style="margin: 0 auto;">
                                <div style="margin: 20px 0">
                                    <img src="../assets/home-bg.jpg" alt="" style="width:1200px;text-align:center">
                                </div>

                            </div>

                        </div>

            <div class="compony">
                <div class="info">

                    <p>
                        <span style="font-weight: bold">客服管理</span>：提供从商务意向到行程出行整个客服服务标准化的流程，设定专属客服，一对一高标准，洞察客户所需，为客户提供精准高效的服务。
                    </p>
                   <p>
                      <span  style="font-weight: bold">客舱管理</span> ：一键智能排班，简单灵活，高效办公；机供品动态监控，自动预警提醒；餐食定制化准备，提升飞行体验品质；乘务人员实时监控，提高乘务人员的利用率，实现资源合理分配。
                   </p>
                    <p>
                        <span  style="font-weight: bold">飞行管理</span>：摒弃原始排班的复杂化方式，实现人员搭配互斥机制，使人员排班更加科学合理；飞行记录实时数据更新，完善人员飞行履历；飞行员实时监控，提高飞行员的利用率，实现资源合理分配。
                    </p>
                    <p>
                        <span  style="font-weight: bold">放行监控</span>：启用航行过程实时动态监控，对于异常情况实时预警提醒。
                    </p>
                    <p>
                        <span  style="font-weight: bold">运行管理</span>：实时在线更新航班批复、气象通告、飞行计划、酒店准备、调组交通、航班保障、代理准备等信息，一键生成最新版本放行资料，自动同步更新航段电子飞行包离线文档，兼顾安全性、时效性、经济性的多维要求，降低成本，提升运行保障质量，提高运行保障服务。
                    </p>
                    <p>
                        <span  style="font-weight: bold">机务管理</span>：将机务排班、岗位搭配等流程智能化，提高效率与合理性；自动监控、到期自动预警，合理配置资源，减能增效，用系统实现全流程数字化管控。
                    </p>
                    <p>
                        <span  style="font-weight: bold">航班监控</span>：实时监控记录航班放行到飞机落地整个过程中的各项数据，形成独立行程飞行数据，保障航班安全正常的运行。实时飞行轨迹监控，掌握整个航班动态和进度，全程辅助运行。
                    </p>
                    <p>
                        <span  style="font-weight: bold">数据中心</span>：为用户提供专属大数据控制台。系统自动采集运行控制环节产生的业务数据，对运行能力、运营能力等各个维度进行数据分析统计，并通过数据仪表盘呈现在数据大屏。
                    </p>


                </div>
            </div>

        </div>
                <section-title
                        title="our strengths"
                        sub-title="我们的优势"
                ></section-title>

                <div class="itemTitle">
                    <ul>
                        <li>
                            <img src="../assets/icon_MV_01@2x.png" alt="">
                            <h2>架构延展性强</h2>
                            <p>系统架构设计轻量级、易用、实用、扩展能力强，能够满足业务广度和深度的扩展，我们的产品是标准化的，但是我们从不怕定制需求。</p>
                        </li>
                        <li>
                            <img src="../assets/icon_MV_02@2x.png" alt="">
                            <h2>业务覆盖性强</h2>
                            <p>业务覆盖航前、航中、航后全过程进行监控管理，既包含静态的基础数据存储、查询、统计</p>
                        </li>
                        <li>
                            <img src="../assets/icon_MV_03@2x.png" alt="">
                            <h2>可视化数据</h2>
                            <p>使用数据可视化手段让业务处理流程更直观；对各类查询统计结果使用丰富的图形、图表进行直观展示</p>
                        </li>
                        <li>
                            <img src="../assets/icon_MV_04@2x.png" alt="">
                            <h2>系统兼容性强</h2>
                            <p>系统预留数据接口，支持与其他平台的数据对接，加强系统的自适应能力，满足客户多平台切换的操作流畅性。</p>
                        </li>
                    </ul>
                </div>

        <div>
            <section-title
                    title="our projects"
                    sub-title="我们的产品"
            ></section-title>
<!--            <div style="margin: 0 auto;max-width:1200px">-->
<!--                <div style="margin: 0 auto;">-->
<!--                    <div style="margin: 20px 0">-->
<!--&lt;!&ndash;                        <Badge status="success" /> <span style="font-size: 16px;font-weight: bold;margin-top: 30px;margin-bottom: 10px;">清晰化飞行数据日历显示界面，数据应有尽有据日历显示界面，操作简单</span>&ndash;&gt;-->
<!--                        <img src="../assets/photo-pic-01.jpg" alt="" style="width:1200px;text-align:center">-->
<!--                    </div>-->
<!--&lt;!&ndash;                    <div style="margin: 20px 0">&ndash;&gt;-->
<!--&lt;!&ndash;                        <Badge status="success" /> <span style="font-size: 16px;font-weight: bold;margin-top: 30px;margin-bottom: 10px;">采用简介的代码编写，后期维护更加方便，开发速度快</span>&ndash;&gt;-->
<!--&lt;!&ndash;                        <img src="../assets/photo-pic-02.jpg" alt="" style="width:1200px;text-align:center">&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--                </div>-->

<!--            </div>-->

            <div style="width:1200px;margin: 0 auto">
                <Carousel v-model="value1" :autoplay="setting.autoplay"
                          :autoplay-speed="setting.autoplaySpeed"
                          :dots="setting.dots"
                          :radius-dot="setting.radiusDot"
                          :trigger="setting.trigger"
                          :arrow="setting.arrow">
                    <CarouselItem>
                        <div class="demo-carousel">
                            <img src="../assets/pic-foc-01.jpg" alt="" style="width:1200px;text-align:center;margin: 0 auto">
                        </div>
                    </CarouselItem>
                    <CarouselItem>
                        <div class="demo-carousel">
                            <img src="../assets/pic-foc-02.jpg" alt="" style="width:1200px;text-align:center;margin: 0 auto">
                        </div>
                    </CarouselItem>
                    <CarouselItem>
                        <div class="demo-carousel">
                            <img src="../assets/pic-foc-03.jpg" alt="" style="width:1200px;text-align:center;margin: 0 auto">
                        </div>
                    </CarouselItem>
                    <CarouselItem>
                        <div class="demo-carousel">
                            <img src="../assets/pic-foc-04.jpg" alt="" style="width:1200px;text-align:center;margin: 0 auto">
                        </div>
                    </CarouselItem>
                </Carousel>
            </div>

        </div>


        <section-title
                title="Intelligent "
                sub-title="我们的荣誉"
        ></section-title>

        <div class="about">
<!--            <div style="margin: 0 auto;width:1200px;">-->
<!--                <p>目前经过我们公司开发的公务航空行业领域产品项目包含：</p>-->
<!--                <p>1.<span style="font-weight: bold;">唯您APP</span> —— 基于FOS系统打造的一款移动端APP智能应用，让客户可通过手机随时查看飞机相关信息，查询过往的飞机运行和财务数据，监控飞机实时状态（包括飞行轨迹、行程计划申请进度、机组准备情况、正在进行的维修工作等）。</p>-->
<!--                <p>2.<span style="font-weight: bold;" >众飞飞行支援中心</span> ——  在线提交PO需求，包含飞行批复、酒店、地服&燃油等，迷你版运行管理平台，包含飞行计划、飞行日志、机队管理、机组管理、客人管理、民航表单等。-->
<!--                </p>-->
<!--                <p> 3.<span style="font-weight: bold;">公务机智维平台</span> ——  针对于国内通航企业开发的一整套的飞机维修控制、生产管理、航材管理、工程管理、质量管理及技术合同的网格化系统平台。-->
<!--                </p>-->
<!--                <p>-->
<!--                    4.<span style="font-weight: bold;">高端商务包机平台</span> —— 特惠包机、定制包机、高端旅游、高端产品、小时卡等。-->
<!--                </p>-->
<!--                <p>-->
<!--                    5.<span style="font-weight: bold;">智慧通航公务机云平台</span> —— 商务管理、客服管理、飞行管理、运行管理、客舱管理、机务管理、质量管理、证件管理、统计管理。-->
<!--                </p>-->
<!--                <ul>-->
<!--                    <li v-for="item of services" :key="item.id">-->
<!--                <span>-->
<!--                    <img :src="item.icon" />-->
<!--                </span>-->
<!--                        <h2>{{ item.title }}</h2>-->
<!--                        <h3>{{ item.subTitle }}</h3>-->
<!--                        <p>{{ item.comments }}</p>-->
<!--                    </li>-->
<!--                </ul>-->
<!--            </div>-->
            <div style="margin: 0 auto;width:1200px;">
                <img src="../assets/soft-bg.png" alt="" style="width:1200px;"/>
            </div>



        </div>


        <Footer></Footer>
        <BackTop :height="100" :bottom="200">
            <div class="top">返回顶部</div>
        </BackTop>
    </div>

</template>
<script>
    import Header from '../components/Header'
    import Footer from '../components/Footer'
    import Banner from '../components/Banner'
    import SectionTitle from '../components/SectionTitle'
export default {
    name: 'Foc',
    components: {
        Header,
        Footer,
        Banner,
        SectionTitle
    },
    data(){
        return {
            bannerImg: require('../assets/banner-projects-04.jpg'),
            title:'智慧通航公务机运行云平台',
            value1:0,
            setting: {
                autoplay: true,
                autoplaySpeed: 3000,
                dots: 'outside',
                radiusDot: true,
                trigger: 'click',
                arrow: 'hover'
            }
        }
    }
}
</script>
<style lang="less" scoped>
h2 {
    /*color: #409eff;*/
    font-weight: bold;
    margin-bottom: 20px;
}
ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 30px;
    li {
        width: 24%;
        background: #f7f8fa;
        margin-bottom: 30px;
        text-align: center;
        padding: 30px;
        box-sizing: border-box;
        transition: all 0.5s;
        h2 {
            font-size: 18px;
            margin: 10px;
        }
        span {
            line-height: 30px;
            text-align: center;
            img {

            }
        }
        &:hover {
            background: #1a88c7;
            color: #fff;
        }
    }
}
/*.content {*/
/*    display: block;*/
/*    height: 1000px;*/
/*    margin: 0 auto;*/
/*    max-width: 1200px;*/
/*    h2 {*/
/*        margin-top: 30px;*/
/*        text-align: center;*/
/*    }*/
/*    .leftContent {*/
/*        width: 40%;*/
/*        float: left;*/
/*        p {*/
/*            text-indent:2em;*/
/*            text-align:justify;*/
/*            text-justify:inter-ideograph;*/
/*            line-height: 30px;*/
/*            img {*/
/*                max-width: 100%;*/
/*            }*/
/*            &:not(:last-child) {*/
/*                margin-bottom: 20px;*/
/*            }*/


/*        }*/
/*    }*/
/*    .rightContent{*/
/*        width: 60%;*/
/*        float: left;*/
/*    }*/

/*}*/

.about {
    max-width: 1200px;
    margin: 0 auto;
    padding: 30px 0 60px;
    .compony {
        margin-top: 30px;
        display: flex;
        .img {
            margin-top:80px;
            flex: 1;
            img {
                max-width: 100%;
            }
        }
        .info {
            flex: 2;
            margin-left: 60px;
            h2 {
                margin-bottom: 20px;
            }
        }
    }
    .team {
        background: url(../assets/project-about-01.jpg) no-repeat right top;
        margin-top: 100px;
        overflow: hidden;
        .text {
            width: 60%;
            background: rgba(238, 238, 238, 0.6);
            padding: 30px;
            margin: 60px;
        }
    }
    p {
        line-height: 30px;
        margin-bottom: 10px;
    }
}



.itemTitle {
    max-width: 1200px;
    margin: 0 auto;
}
.top{
    padding: 10px;
    background: rgba(0, 153, 229, .7);
    color: #fff;
    text-align: center;
    border-radius: 2px;
}
</style>
