<template>

    <div>
        <div>
            <Menu mode="horizontal" :theme="theme1" :active-name="num"  class="header" @on-select="menuChoice">
                <div class="logo">
                      <a href="/"> <img src="../assets/logo.png" style="width: 135px;height: 40px;line-height: 40px;margin-top: 34px" /></a>
                </div>
                <MenuItem name="1" >
                    <span @click="navTo(navs[0].link)">首页</span>
                </MenuItem>

<!--                <MenuItem name="2">-->
<!--                    <span @click="navTo(navs[1].link)">智慧通航</span>-->
<!--                </MenuItem>-->

                <!--暂时隐藏-->
                <Submenu name="2">
                    <template slot="title">
                       <span>智慧通航</span>
                    </template>
<!--                     <MenuItem name="2-1" to="/foc">FOC运行平台</MenuItem>-->
<!--                     <MenuItem name="2-2" to="/trajectory">飞行轨迹监控</MenuItem>-->
<!--                     <MenuItem name="2-3" to="/repair">维修管理平台</MenuItem>-->

                    <MenuItem name="2-1" to="/foc">公务机运行云平台</MenuItem>
                    <MenuItem name="2-3" to="/repair">公务机机务维修平台</MenuItem>
                </Submenu>

                <MenuItem name="3">
                    <span @click="navTo(navs[2].link)">软件服务</span>
                </MenuItem>
<!--                <Submenu name="3">-->
<!--                    <template slot="title">-->
<!--                        <span   @click="navTo(navs[2].link)">软件服务</span>-->
<!--                    </template>-->
<!--                    <MenuItem name="3-1" to="/serviceSoft">软件定制开发</MenuItem>-->
<!--                    <MenuItem name="3-2" to="/serviceApp">移动端定制开发</MenuItem>-->
<!--                    <MenuItem name="3-3" to="/ServiceParams">小程序开发</MenuItem>-->
<!--                    <MenuItem name="3-4" to="/ServiceWechatOpration">微信运营推广</MenuItem>-->

<!--                </Submenu>-->
                <MenuItem name="4">
                    <span   @click="navTo(navs[3].link)">关于我们</span>
                </MenuItem>
<!--                <MenuItem name="5">-->
<!--                   <span   @click="navTo(navs[4].link)">联系我们</span>-->
<!--                </MenuItem>-->
            </Menu>
        </div>


    </div>
</template>
<script>
export default {
    name: 'Header',
    props: {
        num:[String,Number]
    },
    watch:{

        num(){},
    },
    data() {
        return {
            theme1: 'light',
            navs: [
                {
                    title: '首页',
                    link: '/',
                    id: Math.random() * 10000,
                    index:0,
                },
                {
                    title: '智慧通航',
                    link: '/foc',
                    id: Math.random() * 10000,
                    index:1,
                },
                {
                    title: '软件服务',
                    link: '/service',
                    id: Math.random() * 10000,
                    index:2
                },
                {
                    title: '关于我们',
                    link: '/about',
                    id: Math.random() * 10000,
                    index:3
                },
                {
                    title: '联系我们',
                    link: '/contact',
                    id: Math.random() * 10000,
                    index:4
                }
            ],
            path: '/'
        }
    },
    methods: {
        navTo(link) {
            this.path = link
            this.$router.push(link)
        },
        menuChoice(item){
            if(item==1){
                this.$router.push('./')
            }else if(item==2){
                this.$router.push('./foc')
            }else if(item==3){
                this.$router.push('./service')
            }else if(item == 4){
                this.$router.push('./about')
            }
        },

    }
}
</script>
<style lang="less" scoped>
.header {
    z-index:10000;
    position: fixed;
    margin: 0 auto;
    height: 80px;
    /*overflow: hidden;*/
    width: 100%;
    line-height: 80px;
    min-width: 1200px;
    background: #fff;
    /*border-bottom: 1px solid #f4f4f4;;*/
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo {
        margin-left: 10%;
        line-height: 80px;
        margin-right: 48%;

    }
    .nav {
        ul {
            display: flex;
            li {
                margin: 0 20px;
                cursor: pointer;
                &:hover {
                    color: #409eff;
                }
                &:active {
                    color: #409eff;
                }
                span.active {
                    color: #409eff;
                    font-weight: bold;
                    font-size: 16px;
                }
            }
        }
    }
}

/* /deep/ .ivu-menu-light.ivu-menu-horizontal .ivu-menu-item, .ivu-menu-light.ivu-menu-horizontal .ivu-menu-submenu{*/
/*    font-size: 16px;*/
/*}*/
/deep/ .ivu-menu-horizontal{
    padding: 0 80px 0 0;
}
/deep/ .ivu-select-dropdown {
    border-radius: 0px;
    margin-top: 0px;
    top:0px;
}
/deep/ .ivu-menu-drop-list{
    border-radius: 0px !important;
    padding: 20px;
    top: 0px !important;
    /deep/ .ivu-menu-item:hover{
       margin: 0px;
       padding: 0px;
       border-radius: 0px;
        background: #409eff;

    }

}


/deep/ .ivu-menu-light.ivu-menu-horizontal .ivu-menu-item, .ivu-menu-light.ivu-menu-horizontal .ivu-menu-submenu:hover{
    /*border-bottom: 2px solid #ccc;*/
    /*border-bottom: none;*/
}

/deep/ .ivu-menu-item:hover{
    border-bottom: none;

}
/deep/.ivu-menu-horizontal .ivu-menu-submenu .ivu-select-dropdown .ivu-menu-item{
    border-bottom: 1px solid #ececec;
    height: 42px;
    line-height: 42px;


}
/deep/.ivu-menu-horizontal .ivu-menu-submenu .ivu-select-dropdown .ivu-menu-item:hover{
    background-color: #fff;
    border-bottom: 2px solid #409eff;
}




</style>
