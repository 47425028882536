<template>
    <div>
        <Header :num="'3'"></Header>
        <Banner :src="bannerImg" :title="title"></Banner>
        <section-title title="our service" sub-title="微信小程序定制"></section-title>
        <div class="service">
            <ul>
                <li>
                    <img src="../assets/1-1P.png" alt="">
                    <h2>订阅号开发</h2>
                    <p>微信订阅号开发帮助企业、商家、媒体、个人打造超级自媒体平台，为新媒体运营者提供更简便的图文、语音、积视频传播模式、和更人性化的用户管理模式。</p>
                </li>
                <li>
                    <img src="../assets/1-2P.png" alt="">
                    <h2>服务号开发</h2>
                    <p>微信服务号的开发为企业、组织定制功能更齐全、更强大的公众号服务平台，一次开发，永久提升企业和组织 的业务能力和用户管理能力，同时让营销变得更简单。</p>
                </li>
                <li>
                    <img src="../assets/1-3P.png" alt="">
                    <h2>企业号开发</h2>
                    <p>微信企业号是微信为企业客户提供的移动应用入口。帮助企业建立员工、上下游供应链与企业IT系统间的连接。利用企业号，企业或第三方合作伙伴可以帮助企业快速、低成本的实现高质量的移动轻应用，实现生产、管理、协作、运营的移动化。</p>
                </li>
                <li>
                    <img src="../assets/1-4P.png" alt="">
                    <h2>小程序开发</h2>
                    <p>微信小程序开发是一种全新的连接用户与服务的方式，它可以在微信内被便捷地获取和传播，把企业商户服务入驻微信小程序开放给微信10亿用户，无需下载、安装、升级，用户搜一搜、扫一扫立刻享受服务极致用户体验！</p>
                </li>
            </ul>
        </div>
        <Footer></Footer>
        <BackTop :height="100" :bottom="200">
            <div class="top">返回顶部</div>
        </BackTop>
    </div>
</template>
<script>
import Header from '../components/Header'
import Footer from '../components/Footer'
import Banner from '../components/Banner'
import SectionTitle from '../components/SectionTitle'

export default {
    name: 'Service',
    components: {
        Header,
        Footer,
        Banner,
        SectionTitle
    },
    data() {
        return {
            bannerImg: require('../assets/params-banner.jpg'),
            bannerImg02: require('../assets/tese04.png'),
            title: '小程序开发',
            services: [
                {
                    icon: require('../assets/icon-service.png'),
                    title: '项目实施',
                    id: Math.floor(Math.random() * 10000),
                    comments:
                        '给企业提供项目实施经验，为各职能部门提供现场培训和远程支持。帮助解决企业信息化过程中遇到的困难，强力推进项目的进行'
                },
                {
                    icon: require('../assets/customer-icon-01.png'),
                    title: '客服服务',
                    id: Math.floor(Math.random() * 10000),
                    comments:
                        '工作时间内提供 QQ、电话、Email等多种方式，实时解决客户使用中遇到的问题。并收集提出的需求之后对软件加以改进。这样为企业提供私人医生式的专业服务'
                },
                {
                    icon: require('../assets/customer-icon-02.png'),
                    title: '软件维护',
                    id: Math.floor(Math.random() * 10000),
                    comments: '为系统的平稳运行提供全方位的维护管理'
                },
                {
                    icon: require('../assets/customer-icon-03.png'),
                    title: '免费升级',
                    id: Math.floor(Math.random() * 10000),
                    comments:
                        '在维护期间免费升级软件。升级内容一般包括：根据中国民用航空局规定变动情况，增加新的软件功能；根据其他客户使用情况，增加通用功能；修复软件缺陷'
                },
                {
                    icon: require('../assets/customer-icon-04.png'),
                    title: '定制开发',
                    id: Math.floor(Math.random() * 10000),
                    comments:
                        '我们有着熟悉通航公司管理，了解民航法规，技术扎实并不断创新的IT研发团队。可结合企业需要定制开发功能，给企业带来各种可能的解决方案'
                },
                {
                    icon: require('../assets/icon-service.png'),
                    title: '项目实施',
                    id: Math.floor(Math.random() * 10000),
                    comments:
                        '给企业提供项目实施经验，为各职能部门提供现场培训和远程支持。帮助解决企业信息化过程中遇到的困难，强力推进项目的进行'
                }
            ]
        }
    },
    methods: {
        toContact(){
            this.$router.push('/contact')
        }
    }
}
</script>
<style lang="less" scoped>
.service {
    max-width: 1200px;
    margin: 0 auto;
    padding-bottom: 30px;
    & > p {
        line-height: 30px;
    }
    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 30px;
        li {
            width: 24%;
            background: #f7f8fa;
            margin-bottom: 30px;
            text-align: center;
            padding: 30px;
            box-sizing: border-box;
            transition: all 0.5s;
            h2 {
                font-size: 18px;
                margin: 10px;
            }
            img {
                width: 30%;
            }
            p {
                line-height: 30px;
            }
            &:hover {
                background: #1a88c7;
                color: #fff;
            }
        }
    }
}

.passAirport02 {
    margin-top: 2px;
    width: 100%;
    height: 300px;
    background: url('../assets/banner-home-make.jpg') no-repeat;
    background-size: 100% 100%;
}
.passAirport2 {
    margin-top: 4px;
    width: 100%;
    height: 300px;
    background: url('../assets/service-02.jpg') no-repeat;
    background-size: 100% 100%;
}
.passAirport4 {
    margin-top: 4px;
    width: 100%;
    height: 300px;
    background: url('../assets/service-04.jpg') no-repeat;
    background-size: 100% 100%;
}
.passAirport3 {
    margin-top: 4px;
    width: 100%;
    height: 300px;
    background: url('../assets/service-03.jpg') no-repeat;
    background-size: 100% 100%;
}
.contentCneter {
    margin: 0 auto;
    max-width: 1200px;
    h2 {
        font-size: 24px;
        font-weight: bold;
        padding-top: 80px;
        color: #fff;
    }
    .titleContent{
        padding: 14px 0;
        p {
            font-size: 14px;
            color: #fff;
        }
    }
    .more {
        margin-top: 14px;
        span{
            color: #fff;
            /*font-size: 16px;*/
            &:hover {
                cursor: pointer;
                color: #409eff;
                /*font-size: 16px;*/
            }

        }
        img {
            &:hover {
                cursor: pointer;
                transform: translateX(-10px);
            }
        }

    }
}

.about {
    max-width: 1200px;
    margin: 0 auto;
    padding: 30px 0 60px;
    .compony {
        margin-top: 30px;
        display: flex;
        .img {
            flex: 1;
            img {
                max-width: 100%;
            }
        }
        .info {
            flex: 2;
            margin-left: 60px;
            h2 {
                margin-bottom: 20px;
            }
            h3{
                margin-bottom: 10px;
                color: #6d6d6d;
                border-bottom: 1px solid #000000;

            }
        }
    }
    .team {
        background: url(../assets/project-about-01.jpg) no-repeat right top;
        margin-top: 100px;
        overflow: hidden;
        .text {
            width: 60%;
            background: rgba(238, 238, 238, 0.6);
            padding: 30px;
            margin: 60px;
        }
    }
    p {
        line-height: 30px;
        margin-bottom: 10px;
    }
}


.passAirport {
    margin-top: 30px;
    width: 100%;
    height: 300px;
    background: url('../assets/service-01.jpg') no-repeat;
    background-size: 100% 100%;
}
.contentCneter {
    margin: 0 auto;
    max-width: 1200px;
    h2 {
        font-size: 24px;
        font-weight: bold;
        padding-top: 80px;
        color: #fff;
    }
    .titleContent{
        padding: 14px 0;
        p {
            font-size: 14px;
            color: #fff;
        }
    }
    .more {
        margin-top: 14px;
        span{
            color: #fff;
            /*font-size: 16px;*/
            &:hover {
                cursor: pointer;
                color: #409eff;
                /*font-size: 16px;*/
            }

        }
        img {
            &:hover {
                cursor: pointer;
                transform: translateX(-10px);
            }
        }

    }
}
.top{
    padding: 10px;
    background: rgba(0, 153, 229, .7);
    color: #fff;
    text-align: center;
    border-radius: 2px;
}
</style>
